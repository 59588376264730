import React from "react";
import DealsDaysCard from "./DealsDaysCard";
import SectionSlider from "./SectionSlider";
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const DealsDaysContainer = () => {
  return (
    <div className="container  ">
      {/* <DealsDaysCard />
      <DealsDaysCard />
      <DealsDaysCard />
      <DealsDaysCard />
      <DealsDaysCard />
      <DealsDaysCard /> */}
      <SectionSlider>
        <SwiperSlide style={{ width: "100%" }}>
          <Link to="#">
            <DealsDaysCard />
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ width: "100%" }}>
          <Link to="#">
            <DealsDaysCard />
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ width: "100%" }}>
          <Link to="#">
            <DealsDaysCard />
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ width: "100%" }}>
          <Link to="#">
            <DealsDaysCard />
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ width: "100%" }}>
          <Link to="#">
            <DealsDaysCard />
          </Link>
        </SwiperSlide>
      </SectionSlider>
    </div>
  );
};

export default DealsDaysContainer;
