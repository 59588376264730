import OtherHeader from "../components/OtherHeader";
import FooterSecondContainer from "../components/FooterSecondContainer";
import FooterThirdContainer from "../components/FooterThirdContainer";
import FooterEndContainer from "../components/FooterEndContainer";
import { TiMessageTyping } from "react-icons/ti";
import { IoIosSend } from "react-icons/io";
import Person from "../images/avatar-3637425_640.webp";
import Avatar from "../images/avatar3.webp";
import "../css/messages.css";
import { useRef, useState } from "react";
import Input from "../components/Input";
import { FaXmark } from "react-icons/fa6";
const Messages = ({ otherPageBanner, banners }) => {
  const modalRef = useRef();
  const closedModalFn = (e) => {
    modalRef.current.classList.toggle("hide_modal");
  };
  const [people, setPeople] = useState([]);
  const [inputValue, setInputValue] = useState("");

  // Değeri handle eden fonksiyon
  const handleAddPerson = (e) => {
    e.preventDefault();
    if (inputValue.trim() === "") {
      return; // Boş girdi engelleniyor
    }
    // Yeni kişi objesi oluşturuyoruz.
    const newPerson = { person: inputValue };

    // Mevcut array'e yeni objeyi ekliyoruz.
    setPeople([...people, newPerson]);

    // Inputu temizliyoruz.
    setInputValue("");
    closedModalFn();
  };
  return (
    <div>
      <OtherHeader
        otherHeaderPagesLink="Çekilişler"
        banners={banners}
        otherPageBanner={otherPageBanner}
      />
      <div className="messages_container container">
        <div className="create_message_modal hide_modal " ref={modalRef}>
          <form
            className="create_message_modal_form"
            onSubmit={handleAddPerson}
          >
            <div className="closed_icon" onClick={closedModalFn}>
              <FaXmark />
            </div>
            <h2>Sohbet oluştur</h2>
            <p>
              Aşağıda bulunan metin alanına, sohbet oluşturmak istediğiniz üye
              adını girebilirsiniz.
            </p>
            <input
              className="inputBox modal_input"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              type="text"
              placeholder="sohbet etmek istediğiniz kişinin üye adını girin.."
            />
            <div className="buttons">
              <button type="submit" className="create_message_btn">
                Sohbet oluştur
              </button>
              <button onClick={closedModalFn}>İptal</button>
            </div>
          </form>
        </div>
        <div className="messages_left_side">
          <div className="create_message" onClick={closedModalFn}>
            <TiMessageTyping size={30} /> <p>Sohbet oluştur</p>
          </div>
          <div className="message_search_input">
            <input type="search" placeholder="sohbet ara" />
          </div>
          <div className="person_list">
            <ul>
              {people.map((item, index) => (
                <li key={index}>
                  <img src={Avatar} alt="" />
                  <p>{item.person}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="messages_right_side">
          {people.length === 0 && (
            <div className="alert_message">
              <img src={Person} alt="" />
              <h2>Önemli Bilgilendirme</h2>
              <p>
                Cyberpazar üzerinden sizinle iletişime geçip, ilanınızı satın
                aldığını iddia eden kişilere kesinlikle itibar etmeyiniz ve
                durumu hemen canlı destek ekibimize rapor ediniz. Cyberpazar,
                kullanıcılarıyla Chat, Facebook veya Instagram gibi platformlar
                üzerinden asla iletişime geçmez!
              </p>
              <h3>İlanınızın Satıldığını Nasıl Anlayabilirsiniz?</h3>
              <p>
                İlanınız satıldığında, size CYBERPAZAR başlığıyla SMS,
                no-reply@cyberpazar.com adresinden bir e-posta ve site
                içerisindeki Bildirimler sekmesinde teslimat mesajı
                gönderilecektir. Bunun dışında gelen mesajları dikkate
                almayınız.
              </p>
              <p>
                Lütfen ilanınızı satın aldığını iddia eden kişilere prim
                vermeyiniz ve durumu hemen destek ekibimize bildiriniz!
                Cyberpazar olarak, sizin desteğinizle daima güvenli bir
                alışveriş platformu olmaya devam edeceğiz.
              </p>{" "}
            </div>
          )}
          {people && (
            <div className="message_right">
              <div className="message_header">
                <img src={Avatar} alt="" />
                <div className="name_activity">
                  <p>Kullanici adi</p>
                  <span>Çevrimiçi</span>
                </div>
              </div>
              <div className="message_area"></div>
              <div className="message_bar">
                <input type="text" placeholder="bir şeyler yazın" />
                <button className="send_btn">
                  <IoIosSend />{" "}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <footer>
        <div className="footer_parent_container">
          {/* <FooterFirstContainer /> */}
          <FooterSecondContainer />
          <FooterThirdContainer />
        </div>
        <FooterEndContainer />
      </footer>
    </div>
  );
};

export default Messages;
