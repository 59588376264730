import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { FcApproval, FcPlus, FcPicture } from "react-icons/fc";
import { FaCrown } from "react-icons/fa";
import CoverImage from "../images/fc-25-cover.jpeg";
import { Link } from "react-router-dom";
import DiscountSvg from "../images/bookmark.png";
import Img from "../images/avatar10.webp"
const MainSecondCard = ({
  data,
  discount = true,
  seller = true,
  premium = false,
}) => {
  const discountPoint = 10;
  const currentPrice = (data.price - (data.price * discountPoint) / 100).toFixed(2);
  const selectedLang = localStorage.getItem("selectedLanguage");
  console.log(data, data.id);
  const formatUrl = (title, id) => {
    const formattedTitle = title
      .replace(/\//g, "-") // / işarəsini əvəz edir
      .replace(/\?/g, "") // ? işarəsini çıxarır
      .trim() // Başda və sondakı boşluqları çıxarır
      .replace(/\s+/g, "") // Aradakı boşluqları çıxarır
      .toLowerCase(); // Kiçik hərflərə çevirir
    if (title === "") {
      return `${id}`;
    }
    return `/${formattedTitle}/${id}`;
  };

  return (
    <>
      <Link to={formatUrl(data.title, data.id)} className="item-card">
        {discount && (
          <div className="discount-icon">
            <p>{discountPoint}%</p>
            <img src={DiscountSvg} alt="" />
          </div>
        )}
        {premium && (
          <div className="item_card_premium_icon" title="premium ilan">
            <FaCrown />
          </div>
        )}

        <div className="card-header">
          <img src={CoverImage} alt="" />
        </div>
        <div className="card-body">
          <p className="card_product_category">{data?.game_category?.title}</p>
          <p className="card_product_title">
            {data?.title.length > 30
              ? data?.title.substring(0, 30) + "..."
              : data?.title}
          </p>
          {/* <p>{data?.description?.substring(0, 30)}...</p> */}
          <div className="main_second_card_down_footer">
            {/* <div className="main_second_card_down_footer_icons">
              <FcApproval className="main_second_card_down_footer_check_icon" />
              <FcPlus className="main_second_card_down_footer_check_icon" />
              <FcPicture className="main_second_card_down_footer_check_icon" />
            </div> */}
            <span className="product_price">
              {currentPrice}{" "}
              <span className="money_type">
                {selectedLang === "az" ? "₼" : "₺"}
              </span>
            </span>
            {discount && (
              <span className="discount-price">
                {data?.price}
                <span className="money_type">
                  {selectedLang === "az" ? "₼" : "₺"}
                </span>
              </span>
            )}
          </div>
        </div>
        {seller ?
          <Link
            to={`/shop-profile/${formatUrl("", data?.seller?.id)}`}
            className="card-footer"
          >
            <div className="seller_image">
              <img src={data?.seller?.cover} alt="" />
            </div>
            <div className="main_second_card_end_seller">
              <span>SATICI</span>
              <p>{data?.seller?.name}</p>
            </div>
          </Link> :
          <Link
            to={`/`}
            className="card-footer"
          >
            <div className="seller_image">
              <img src={Img} alt="" />
            </div>
            <div className="main_second_card_end_seller">
              <span>SATICI</span>
              <p>Cyberpazar</p>
            </div>
          </Link>}
      </Link>
    </>
  );
};

export default MainSecondCard;
