import React, { useRef } from "react";
import { supportSystem } from "../../mock/data";
import { FaXmark } from "react-icons/fa6";
import { IoInformationCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const SupportAlertModal = () => {
  const navigation = useNavigate();
  const acceptRef = useRef();
  const acceptFn = () => {
    acceptRef.current.className = "hidden_modal";
  };
  return (
    <div className="support_alert_modal" ref={acceptRef}>
      <div className="support_alert_container">
        <div className="xmark_icon">
          <FaXmark />
        </div>
        <div className="info_icon">
          <IoInformationCircleOutline />
        </div>
        <h2>Destek Talebi Oluşturma Kuralları</h2>
        <div className="support_alert_inner">
          {/* Cevaplama Süresi ve Süreç İşleyişi */}
          <section>
            <h2>{supportSystem.responseTimeAndProcess.title}</h2>
            <p>{supportSystem.responseTimeAndProcess.description}</p>
            <ul>
              {supportSystem.responseTimeAndProcess.processDetails.map(
                (detail, index) => (
                  <li key={index}>{detail}</li>
                )
              )}
            </ul>
          </section>

          {/* Destek Talebi Oluşturma Gereksinimleri */}
          <section>
            <h2>{supportSystem.requestCreationRequirements.title}</h2>
            <h3>Ürün Desteği</h3>
            <ul>
              {supportSystem.requestCreationRequirements.productSupport.map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>

            <h3>Dijital Ürün İade Politikası</h3>
            <ul>
              {supportSystem.requestCreationRequirements.digitalProductReturnPolicy.map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>

            <h3>Spam Talep Uyarısı</h3>
            <ul>
              {supportSystem.requestCreationRequirements.spamRequestsWarning.map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>

            <p>
              {supportSystem.requestCreationRequirements.evidenceSubmission}
            </p>
          </section>

          {/* Destek Sistemi Hakkında Bilgiler */}
          <section>
            <h2>{supportSystem.supportSystemInformation.title}</h2>
            <p>{supportSystem.supportSystemInformation.description}</p>

            <h3>Sipariş Öncesi Sorular</h3>
            <p>{supportSystem.supportSystemInformation.preOrderQuestions}</p>

            <h3>Hesap Geri Alınma Durumları</h3>
            <ul>
              {supportSystem.supportSystemInformation.accountRecovery.map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>

            <h3>Destek Sistemi Kötüye Kullanımı</h3>
            <ul>
              {supportSystem.supportSystemInformation.misuseOfSupport.map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>

            <h3>Uygunsuz Mesajlar</h3>
            <p>
              {supportSystem.supportSystemInformation.inappropriateMessages}
            </p>
          </section>
        </div>
        <div className="support_buttons">
          <button className="accept_button" onClick={acceptFn}>
            Kabul et ve devam et
          </button>{" "}
          <button
            className="reject_button"
            onClick={() => navigation("/support")}
          >
            Reddet
          </button>
        </div>
      </div>
    </div>
  );
};

export default SupportAlertModal;
