import React, { useEffect, useState } from "react";
import Input from "./Input";
import { categories } from "../mock/data";
import { FaFile } from "react-icons/fa";
import SupportAlertModal from "./modals/SupportAlertModal";
import { useLocation } from "react-router-dom";

const NewSupportRequest = () => {
  const location = useLocation();
  const [hash, setHash] = useState("#ProfileSummary");

  useEffect(() => {
    setHash(location.hash);
  }, [location]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const handleCategoryChange = (e) => {
    const selected = e.target.value;
    setSelectedCategory(selected);

    // Seçilen kategoriye göre alt kategorileri ayarla
    const categoryObj = categories.find((cat) => cat.category === selected);
    setSubcategories(categoryObj ? categoryObj.subcategories : []);
  };
  return (
    <>
      <div className="passwordChange">
        <h2> Yeni Talep oluştur </h2>
        <form className="passwordChange__form">
          <label>
            Talep Başlığı
            <Input type="text" />
          </label>

          <label htmlFor="category">Kategori:</label>
          <select
            className="support_request_select_input"
            id="category"
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <option value="">Bir kategori seçin</option>
            {categories.map((cat, index) => (
              <option key={index} value={cat.category}>
                {cat.category.replace("_", " ").toUpperCase()}
              </option>
            ))}
          </select>

          {/* Alt kategori dropdown */}
          <br />
          <br />
          <label htmlFor="subcategory">Alt Kategori:</label>
          <select
            id="subcategory"
            disabled={!selectedCategory}
            className="support_request_select_input"
          >
            <option value="">Bir alt kategori seçin</option>
            {subcategories.map((subcat, index) => (
              <option key={index} value={subcat}>
                {subcat}
              </option>
            ))}
          </select>

          <label>
            Talep İçeriği
            <textarea className="support_request_textarea"> </textarea>
          </label>
          <label htmlFor="support_file" className="support_request_select_file">
            <span>
              Talep Ek Görselleri <FaFile />
            </span>
            <input id="support_file" type="file" accept="image/*" hidden />
          </label>

          <button> Talep gönder </button>
        </form>
      </div>
      {hash == "#new-support-request" && <SupportAlertModal />}
    </>
  );
};

export default NewSupportRequest;
