import React, { useState } from "react";
import { BiLike } from "react-icons/bi";
import { IoIosChatbubbles } from "react-icons/io";
import { RiUserFollowFill } from "react-icons/ri";
import ShopProfileImg from "../images/shopprofileimg.webp";
import { FaStar } from "react-icons/fa";
import Avatar from "../images/avatar2.webp";
const ShopProfileSectionFirstHeader = ({ shopProducts }) => {
  const [isActive, setIsActive] = useState(true);
  const [expiredTime, setExpiredTime] = useState("49");

  return (
    <div className="shop_profile_section_first_header">
      <div className="shop_profile_section_first_header_left">
        <div className="shop_profile_section_first_header_left_seller_profile_img">
          <img src={Avatar} alt="" />
        </div>
        <div className="shop_profile_section_first_header_seller_name">
          <div className="shop_profile_active">
            <h2>{shopProducts[0]?.seller?.name}</h2>
            {isActive ? (
              <span className="active_pin"></span>
            ) : (
              <span className="expired">{expiredTime}dk önce</span>
            )}
          </div>
          <p>
            <span>9.5</span> / 10
          </p>
        </div>
      </div>
      {/* <div className="shop_profile_section_first_header_left">
        <img src={shopProducts[0]?.seller?.cover} alt="" />
        <div className="shop_profile_section_first_header_left_content">
          <span>4 saat önce aktifti.</span>
          <h2>{shopProducts[0]?.seller?.name}</h2>
          <div className="all_shops_card_footer_buttons">
            <div style={{ display: "none" }}>
              <BiLike />
              62276
            </div>
            <div>182</div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="all_shops_card_middle ">
            
            <button>
              <RiUserFollowFill /> Takib Et
            </button>
            <button>
              <IoIosChatbubbles /> Sohbet
            </button>
          </div>
        </div>
      </div> */}
      {/* <div className="shop_profile_section_first_header_right">
        <span>Üyelik Tarihi : 6 Şubat 2021</span>
        <div className="shop_profile_section_first_header_right_star">
          <FaStar className="shop_profile_section_first_header_right_star_icon" />
          <p>
            <span>9.5</span> / 10
          </p>
        </div>
        <p className="shop_profile_section_first_header_right_second">
          Toplam 612 mağaza değerlendirmesi
        </p>
        <p className="shop_profile_section_first_header_right_end">
          1,902 Başarılı İşlem
        </p>
      </div> */}
      <div className="shop_profile_section_first_header_right">
        Kayıt Tarihi : 05.01.2024 | 20:46
      </div>
    </div>
  );
};

export default ShopProfileSectionFirstHeader;
