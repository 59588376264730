import React, { useEffect, useState } from "react";
import {
  FaBalanceScale,
  FaBoxOpen,
  FaSearch,
  FaTrophy,
  FaUsers,
} from "react-icons/fa";
import logoImg1 from "../images/searchlogo.webp";
import logoImg2 from "../images/medal.webp";
import logoImg3 from "../images/follow.webp";
import ShopProfileFilterCardsContainer from "./ShopProfileFilterCardsContainer";
import RecommendedShopAndProduct from "./RecommendedShopAndProduct";
import Achievements from "./Achievements";
import { useSelector } from "react-redux";
import { IoGameController } from "react-icons/io5";
const ProfileDetailMainSecond = () => {
  const [activeButton, setActiveButton] = useState("İlanlar");
  const { shopProducts } = useSelector((state) => state.Data);
  console.log(shopProducts);
  // Use useEffect to load the active button from localStorage on component mount
  useEffect(() => {
    const savedActiveButton = localStorage.getItem("activeButton") || "İlanlar";
    setActiveButton(savedActiveButton);
  }, []);

  // Handler function to change the active button and save it to localStorage
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    localStorage.setItem("activeButton", buttonName);
  };
  const renderComponent = () => {
    switch (activeButton) {
      case "İlanlar":
        return <ShopProfileFilterCardsContainer shopProducts={shopProducts} />;
      case "Değerlendirmeler":
        return <RecommendedShopAndProduct />;
      case "Başarımlar":
        return <Achievements />;

      default:
        return <ShopProfileFilterCardsContainer shopProducts={shopProducts} />;
    }
  };
  return (
    <div className="shop_profile_section_first_main">
      <div className="main_second_buttons container">
        <div className="main_second_buttons_inner">
          <button
            className={
              activeButton === "İlanlar" ? "main_second_active_button" : ""
            }
            onClick={() => handleButtonClick("İlanlar")}
          >
            <IoGameController /> İlanlar
          </button>

          <button
            className={
              activeButton === "Değerlendirmeler"
                ? "main_second_active_button"
                : ""
            }
            onClick={() => handleButtonClick("Değerlendirmeler")}
          >
            <FaBalanceScale /> Değerlendirmeler
          </button>

          <button
            className={
              activeButton === "Başarımlar" ? "main_second_active_button" : ""
            }
            onClick={() => handleButtonClick("Başarımlar")}
          >
            <FaTrophy /> Başarımlar
          </button>

          {/* <button
            className={
              activeButton === "Takipçiler" ? "main_second_active_button" : ""
            }
            onClick={() => handleButtonClick("Takipçiler")}
          >
            <FaUsers /> Takipçiler
          </button> */}
        </div>
        <div className="search_bar">
          <input type="text" placeholder="Mağazada ara..." />
          <FaSearch className="search_bar_icon" />
        </div>
      </div>

      <div className="shop_profile_section_first_main_filter">
        <div className="player_market_section_first_container container">
          {/* <ShopProfileAnnounceFilterContainer /> */}
          {/* <ShopProfileFilterCardsContainer shopProducts={shopProducts} /> */}
          {renderComponent()}
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailMainSecond;
