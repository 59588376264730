import React, { useEffect, useState } from "react";

/* Images */

import billingImg1 from "../../images/billingImg1.webp";
import billingImg2 from "../../images/billingImg2.webp";
import billingImg3 from "../../images/billingImg3.webp";
import billingImg4 from "../../images/billingImg4.webp";
import communityImg from "../../images/level-badge.webp";
import summaryImg from "../../images/2125352.svg";
import PasswordChange from "./PasswordChange";
import MailChange from "./MailChange";
import NumberChange from "./NumberChange";
import { Link, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { FcMoneyTransfer, FcOk } from "react-icons/fc";
import { FaXmark } from "react-icons/fa6";
const ProfileSummary = ({ userObj }) => {
  const shopNameChangePrice = 50;
  const navigate = useNavigate();
  const siteURL = window.location;
  // const [nowShopAdress,setNowShopAdress]=useState(userObj.username);
  const [shopState, setShopState] = useState(false);
  const [shopEditSuccess, setShopEditSuccess] = useState(false);
  const [shopEditFailed, setShopEditFailed] = useState(false);
  const [userData, setUserData] = useState({
    username: userObj.username || "",
    userLink: "",
  });
  const [rangeSuccess, setRangeSuccess] = useState(34); /*example: 34*/
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  useEffect(() => {
    setUserData({
      username: userObj.username,
      userLink: `${siteURL.origin}/profile/${userObj.username}`,
    });
  }, [userObj]);

  function handleChange(value) {
    setUserData({
      username: value,
      userLink: `${siteURL.origin}/profile/${value}`,
    });
  }
  const createShopBtnFn = () => {
    // navigate("/control-center#MembershipPackages");
    setShopState(true);
  };
  const editShopNameFn = () => {
    setPopupIsOpen(true);
  };

  function paymentShopNameEditFn() {
    if (100 <= shopNameChangePrice) {
      setShopEditFailed(true);
    } else {
      setShopEditFailed(false);

      setShopEditSuccess(true);
      setPopupIsOpen(false);
    }
  }

  return (
    <div>
      <div className="profile_detail_right_container">
        <h3>Mağaza Adresi Belirle</h3>
        <p>
          Merhaba Mansur, Hesabını güvenilir hesap statüsüne yükselttikten sonra
          mağaza adresi belirleme hakkı kazanabilirsin.{" "}
          <span>
            {" "}
            Bu hakkını yalnızca 1 kez kullanabileceksin. mağaza ismini tekrar
            değiştirmek için ekstra ücret ödemeniz gerekecek.
          </span>{" "}
          Bu alandan yalnızca profilinizin adresini (URL) değiştirirsiniz.
          Kullanıcı adınız değişmemektedir.
        </p>
        {!shopState ? (
          <button
            className="profile_summary_create_shop_btn"
            onClick={createShopBtnFn}
          >
            {" "}
            <FaPlus /> Mağaza yarat
          </button>
        ) : (
          <>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="profile_detail_label_inp">
                <label htmlFor="adress">Şuanki Mağaza Adresiniz</label>
                <input
                  id="adress"
                  type="text"
                  value={userData.username}
                  onChange={(e) => handleChange(e.target.value)}
                />
              </div>
              <div className="profile_detail_label_inp">
                <label htmlFor="shop_profile">Oluşacak Mağaza Adresi</label>
                <input
                  id="shop_profile"
                  type="text"
                  value={userData.userLink}
                  disabled
                />
              </div>
              <button onClick={editShopNameFn}>Güncelle</button>
              {popupIsOpen && (
                <div className="profile_detail_create_shop_popup">
                  <div className="profile_detail_payment_methods">
                    <div
                      className="profile_detail_payment_closed_icon"
                      onClick={() => setPopupIsOpen(!popupIsOpen)}
                    >
                      <FaXmark />
                    </div>
                    <span className="profile_detail_payment_balance">
                      <span>
                        <FcMoneyTransfer /> Bakiyeniz:{" "}
                        <span>{userObj.balance}</span>
                      </span>
                      <span
                        onClick={() => navigate("/control-center#BankAccounts")}
                      >
                        <FaPlus title="BAKIYENI ARTIR" />
                        bakiyeni artır
                      </span>
                    </span>
                    <label>
                      tutar:
                      <span>{shopNameChangePrice} TL</span>
                    </label>
                    {shopEditFailed && (
                      <div className="failed">
                        <p>
                          bakiyeniz yeterli değil lütfen bakiyenizi artırın!
                        </p>
                      </div>
                    )}

                    <button onClick={paymentShopNameEditFn}>ödemek</button>
                  </div>
                </div>
              )}
              {shopEditSuccess && (
                <div className="profile_detail_create_shop_success_messages_popup">
                  <div className="profile_detail_payment_success">
                    <div className="profile_detail_payment_success_icon">
                      <FcOk />
                    </div>
                    <p>ödeme başarıyla gerçekleştirildi!</p>
                    <span onClick={() => setShopEditSuccess(!shopEditSuccess)}>
                      kapat
                    </span>
                  </div>
                </div>
              )}
            </form>
          </>
        )}
      </div>
      <PasswordChange userObj={userObj} />
      <MailChange userObj={userObj} />
      <NumberChange userObj={userObj} />
      <div className="profileSummary">
        <div className="profileSummary__billing">
          <h3>Hesap Özeti</h3>
          <div className="profileSummary__billing--main">
            <div className="profileSummary__billing--box">
              <div className="profileSummary__billing--boxImg">
                <img src={billingImg1} alt="" />
              </div>
              <div className="profileSummary__billing--boxContent">
                <strong> 0.00 TL </strong>
                <span> Hesap Bakiyesi </span>
              </div>
            </div>
            <div className="profileSummary__billing--box">
              <div className="profileSummary__billing--boxImg">
                <img src={billingImg2} alt="" />
              </div>
              <div className="profileSummary__billing--boxContent">
                <strong> 0 </strong>
                <span> Satılan Toplam İlan </span>
              </div>
            </div>
            <div className="profileSummary__billing--box">
              <div className="profileSummary__billing--boxImg">
                <img src={billingImg3} alt="" />
              </div>
              <div className="profileSummary__billing--boxContent">
                <strong> 0.00 TL </strong>
                <span> Çekilebilir Tutar </span>
              </div>
            </div>
            <div className="profileSummary__billing--box">
              <div className="profileSummary__billing--boxImg">
                <img src={billingImg4} alt="" />
              </div>
              <div className="profileSummary__billing--boxContent">
                <strong> 0.00 TL </strong>
                <span> Toplam Kazanç </span>
              </div>
            </div>
          </div>
        </div>

        <div className="profileSummary__community">
          <h3> Seviyye serisi</h3>
          <div className="profileSummary__community--box">
            <div
              className="profileSummary__community--boxImg"
              style={{ backgroundImage: `url(${communityImg})` }}
            >
              <span>SEVIYE</span>
              <strong>1</strong>
            </div>
            <div className="profileSummary__community--boxContent">
              <div className="profileSummary__community--boxText">
                <p>
                  <strong>+45 EXP </strong>
                  seviye atlamak için gerekiyor
                </p>
                <span>0 SAHİP OLUNAN TOPLAM EXP</span>
              </div>
              <div className="profileSummary__community--range">
                <div
                  style={{ width: `${rangeSuccess}%` }}
                  className="profileSummary__community--rangeSuccess"
                ></div>
                <span> {rangeSuccess || 0}%</span>
              </div>
            </div>
          </div>
        </div>

        <div className="profileSummary__advert">
          <h3> İlan Özeti </h3>
          <div className="profileSummary__advert--box">
            <div className="profileSummary__advert--img">
              <img src={summaryImg} alt="" />
            </div>
            <h4> İlan bulunamadı </h4>
            <p> Aktif ilanınız bulunmamaktadır. </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSummary;
