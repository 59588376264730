import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderFirstContainer from "../components/HeaderFirstContainer";
import HeaderSecondContainer from "../components/HeaderSecondContainer";
import HeaderThirdContainer from "../components/HeaderThirdContainer";
import HeaderFourthContainer from "../components/HeaderFourthContainer";
import HeaderFifthContainer from "../components/HeaderFifthContainer";
import HeaderLiveSupportContainer from "../components/HeaderLiveSupportContainer";
import MainFirstContainer from "../components/MainFirstContainer";
import MainSecondContainer from "../components/MainSecondContainer";
import MainThirdContainer from "../components/MainThirdContainer";
import NewsSectionContainer from "../components/NewsSectionContainer";
import PlayerMarketSectionContainer from "../components/PlayerMarketSectionContainer";
import FooterFirstContainer from "../components/FooterFirstContainer";
import FooterSecondContainer from "../components/FooterSecondContainer";
import FooterThirdContainer from "../components/FooterThirdContainer";
import FooterEndContainer from "../components/FooterEndContainer";
import MainSecondButtons from "../components/MainSecondButtons";
import DealsDaysButtons from "../components/DealsDaysButtons";
import DealsDaysContainer from "../components/DealsDaysContainer";
import NewSectionFlex from "../components/NewSectionFlex";
import SectionSlider from "../components/SectionSlider";
import MainThirdButtons from "../components/MainThirdButtons";
import MainFoutrhContainer from "../components/MainFoutrhContainer";
import { SwiperSlide } from "swiper/react";
import TrendCategoryCard from "../components/TrendCategoryCard";
import TrendCategorySection from "../components/TrendCategorySection";
import BestSellingProductsSection from "../components/BestSellingProductsSection";
import FooterPayment from "../components/FooterPayment";
import FooterContactSection from "../components/FooterContactSection";
import FooterNavbar from "../components/FooterNavbar";

const Home = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("");
  const [activeFourthButton, setActiveFourthButton] = useState("");

  useEffect(() => {
    const savedActiveButton =
      localStorage.getItem("activeButton") || "Popüler İlanlar";
    setActiveButton(savedActiveButton);
    const savedFourthActiveButton =
      localStorage.getItem("fourthActiveButton") || "Valorant";
    setActiveFourthButton(savedFourthActiveButton);
  }, []);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    localStorage.setItem("activeButton", buttonName);
  };
  const handleFourthButtonClick = (buttonName) => {
    setActiveFourthButton(buttonName);
    localStorage.setItem("fourthActiveButton", buttonName);
  };

  const allNews = () => {
    navigate("/news");
  };

  const allAuctions = () => {
    navigate("/auctions");
  };

  return (
    <div>
      <header>
        <HeaderFirstContainer />
        <HeaderFourthContainer />
        <HeaderSecondContainer />
        <HeaderThirdContainer />
        <HeaderFifthContainer />
        <HeaderLiveSupportContainer />
      </header>
      <main>
        <section>
          <MainFirstContainer />
        </section>
        <section>
          <MainSecondButtons
            activeButton={activeButton}
            onButtonClick={handleButtonClick}
          />
          <MainSecondContainer activeButton={activeButton} />
        </section>
        <section>
          <h2 className="section_header">Tüm ilanlar</h2>
          <MainThirdContainer />
        </section>
        <section>
          <MainThirdButtons
            activeFourthButton={activeFourthButton}
            onButtonClick={handleFourthButtonClick}
          />
          <MainFoutrhContainer activeFourthButton={activeFourthButton} />
        </section>
        <section>
          <DealsDaysButtons />
          <DealsDaysContainer />
        </section>
        {/* <section className="container">
          <h2 className="section_header">Blog</h2>
          <NewsSectionContainer />
          <button onClick={allNews} className="view_all">
            Tüm Bloglar
          </button>
        </section> */}
        <section className="container">
          {/* Flex Cards */}
          {/* <p>Salam mən burdayam</p> */}
          <NewSectionFlex />
        </section>

        <TrendCategorySection />

        {/* <BestSellingProductsSection /> */}

        {/* <section className="container">
          <h2 className="section_header">Türkiye'nin En Büyük Oyuncu Pazarı</h2>
          <PlayerMarketSectionContainer />
        </section> */}
      </main>
      <footer>
        <div className="footer_parent_container">
          {/* <FooterFirstContainer /> */}
          <FooterThirdContainer />
        </div>
        <FooterPayment />
        {/* <FooterContactSection /> */}
        <FooterSecondContainer />
        <FooterEndContainer />
        {/* <FooterNavbar /> */}
      </footer>
    </div>
  );
};

export default Home;
