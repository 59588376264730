import godOfWarRagnarok from "../images/god-of-war.webp";

export const reviews = [
  {
    game: "PUBG",
    user: "gamer01",
    rating: 5,
    date: "2024-01-01",
    message: "Harika bir oyun, grafikleri ve oynanışı çok iyi!",
  },
  {
    game: "Valorant",
    user: "noobMaster",
    rating: 4,
    date: "2024-01-02",
    message: "Fena değil ama biraz dengelenmesi gerekiyor.",
  },
  {
    game: "CS:GO",
    user: "shooterPro",
    rating: 5,
    date: "2024-01-03",
    message: "En sevdiğim FPS oyunu, her gün oynuyorum.",
  },
  {
    game: "FC25",
    user: "footballFan",
    rating: 3,
    date: "2024-01-04",
    message: "Fena değil, ama önceki versiyon daha iyiydi.",
  },
  {
    game: "CD Key",
    user: "keyCollector",
    rating: 4,
    date: "2024-01-05",
    message: "Çok hızlı teslimat ve uygun fiyatlar.",
  },
  {
    game: "PUBG",
    user: "sniperWolf",
    rating: 5,
    date: "2024-01-06",
    message: "Keskin nişancı tüfeğiyle çok eğlenceli!",
  },
  {
    game: "Valorant",
    user: "quickShot",
    rating: 4,
    date: "2024-01-07",
    message: "Güzel oyun, ama karakterler arasında daha fazla denge olmalı.",
  },
  {
    game: "CS:GO",
    user: "bombMaster",
    rating: 5,
    date: "2024-01-08",
    message: "Bomba kurma ve çözme mekanikleri mükemmel.",
  },
  {
    game: "FC25",
    user: "goalKing",
    rating: 2,
    date: "2024-01-09",
    message: "Beni hayal kırıklığına uğrattı, eski versiyonlar daha iyiydi.",
  },
  {
    game: "CD Key",
    user: "fastBuyer",
    rating: 5,
    date: "2024-01-10",
    message: "Hızlı ve güvenilir bir hizmet, tavsiye ederim.",
  },
  {
    game: "PUBG",
    user: "warriorX",
    rating: 4,
    date: "2024-01-11",
    message: "Takım oyunuyla çok daha eğlenceli!",
  },
  {
    game: "Valorant",
    user: "sneakyNinja",
    rating: 3,
    date: "2024-01-12",
    message: "Güzel oyun ama sürekli güncellemeler gerekli.",
  },
  {
    game: "CS:GO",
    user: "AK47Master",
    rating: 5,
    date: "2024-01-13",
    message: "AK47 ile oynamak çok zevkli!",
  },
  {
    game: "FC25",
    user: "soccerFan",
    rating: 4,
    date: "2024-01-14",
    message: "Güzel oyun ama ufak tefek hatalar var.",
  },
  {
    game: "CD Key",
    user: "dealHunter",
    rating: 5,
    date: "2024-01-15",
    message: "Harika fırsatlar, kesinlikle göz atmalısınız.",
  },
  {
    game: "PUBG",
    user: "survivorQueen",
    rating: 5,
    date: "2024-01-16",
    message: "Harita ve mod çeşitliliği mükemmel.",
  },
  {
    game: "Valorant",
    user: "headshotKing",
    rating: 4,
    date: "2024-01-17",
    message: "Yüksek aksiyonlu, takım tabanlı oyunlar için ideal.",
  },
  {
    game: "CS:GO",
    user: "bombExpert",
    rating: 5,
    date: "2024-01-18",
    message: "Taktiksel oyunlar sevenler için vazgeçilmez.",
  },
  {
    game: "FC25",
    user: "midfielderPro",
    rating: 3,
    date: "2024-01-19",
    message: "Yenilik bekliyordum, pek bir fark yok.",
  },
  {
    game: "CD Key",
    user: "keyMaster",
    rating: 5,
    date: "2024-01-20",
    message: "Çok hızlı teslimat, memnun kaldım.",
  },
  {
    game: "PUBG",
    user: "sniperLegend",
    rating: 5,
    date: "2024-01-21",
    message: "Uzun menzilli silahlar çok dengeli.",
  },
  {
    game: "Valorant",
    user: "flankMaster",
    rating: 4,
    date: "2024-01-22",
    message: "Flank pozisyonlarıyla oynamak çok zevkli.",
  },
  {
    game: "CS:GO",
    user: "sharpshooter",
    rating: 5,
    date: "2024-01-23",
    message: "Daha iyi bir FPS bulmak zor.",
  },
  {
    game: "FC25",
    user: "goalMachine",
    rating: 3,
    date: "2024-01-24",
    message: "Beklentilerimi tam karşılamadı ama yine de oynanabilir.",
  },
  {
    game: "CD Key",
    user: "dealSeeker",
    rating: 5,
    date: "2024-01-25",
    message: "Mükemmel fiyatlar ve güvenilir satıcı.",
  },
  {
    game: "PUBG",
    user: "battleExpert",
    rating: 5,
    date: "2024-01-26",
    message: "Savaş alanı hiç bu kadar heyecanlı olmamıştı.",
  },
  {
    game: "Valorant",
    user: "stealthAssassin",
    rating: 3,
    date: "2024-01-27",
    message: "Güzel ama bazen bağlantı sorunları yaşanıyor.",
  },
  {
    game: "CS:GO",
    user: "headshotMaster",
    rating: 5,
    date: "2024-01-28",
    message: "Başarılı stratejilerle galibiyet çok tatmin edici.",
  },
  {
    game: "FC25",
    user: "footballStar",
    rating: 2,
    date: "2024-01-29",
    message: "Hayal kırıklığına uğrattı, daha iyi olabilirdi.",
  },
  {
    game: "CD Key",
    user: "quickBuyer",
    rating: 5,
    date: "2024-01-30",
    message: "Hızlı teslimat, kesinlikle tavsiye ederim.",
  },
  {
    game: "PUBG",
    user: "loneWolf",
    rating: 4,
    date: "2024-01-31",
    message: "Solo oynamak bazen zorlayıcı ama yine de güzel.",
  },
  {
    game: "Valorant",
    user: "precisionShooter",
    rating: 4,
    date: "2024-02-01",
    message: "Keskin nişancılar için ideal.",
  },
  {
    game: "CS:GO",
    user: "rushMaster",
    rating: 5,
    date: "2024-02-02",
    message: "Hızlı oyun modları çok heyecan verici.",
  },
  {
    game: "FC25",
    user: "defenderPro",
    rating: 3,
    date: "2024-02-03",
    message: "Grafikler iyi ama oyun mekaniği geliştirilmeli.",
  },
  {
    game: "CD Key",
    user: "discountHunter",
    rating: 4,
    date: "2024-02-04",
    message: "İyi fiyatlar ama biraz daha indirim olabilir.",
  },
  {
    game: "PUBG",
    user: "survivorKing",
    rating: 5,
    date: "2024-02-05",
    message: "Survival hissini çok iyi veriyor.",
  },
  {
    game: "Valorant",
    user: "stealthMaster",
    rating: 3,
    date: "2024-02-06",
    message: "Bazı karakterler aşırı güçlü, dengelenmeli.",
  },
  {
    game: "CS:GO",
    user: "grenadeExpert",
    rating: 5,
    date: "2024-02-07",
    message: "Grenade kullanımı strateji açısından çok önemli.",
  },
  {
    game: "FC25",
    user: "wingerStar",
    rating: 4,
    date: "2024-02-08",
    message: "Kanat oyuncuları için harika animasyonlar var.",
  },
  {
    game: "CD Key",
    user: "trustedBuyer",
    rating: 5,
    date: "2024-02-09",
    message: "Güvenilir ve hızlı alışveriş deneyimi.",
  },
  {
    game: "PUBG",
    user: "warzoneMaster",
    rating: 4,
    date: "2024-02-10",
    message: "Savaş modları çok çeşitli, sıkılmıyorsunuz.",
  },
  {
    game: "Valorant",
    user: "clutchMaster",
    rating: 5,
    date: "2024-02-11",
    message: "Clutch pozisyonlarını oynamak çok keyifli.",
  },
  {
    game: "CS:GO",
    user: "sprayKing",
    rating: 4,
    date: "2024-02-12",
    message: "Silah spray mekanikleri çok iyi ayarlanmış.",
  },
  {
    game: "FC25",
    user: "forwardPro",
    rating: 3,
    date: "2024-02-13",
    message: "Forvet pozisyonları çok güçlü, dengelenmesi gerek.",
  },
  {
    game: "CD Key",
    user: "bestDeals",
    rating: 5,
    date: "2024-02-14",
    message: "Mükemmel fırsatlar ve güvenilir hizmet.",
  },
];
export const countries = [
  {
    id: "turkey",
    label: "Türkiye",
    imgSrc:
      "https://anatoliaaerodesign.com/wp-content/uploads/2018/09/hd-turk-bayragi-png-2.png",
    imgAlt: "Turkey",
  },
  {
    id: "india",
    label: "Hindistan",
    imgSrc:
      "https://flagdownload.com/wp-content/uploads/Flag_of_Turkey-128x85.png",
    imgAlt: "India",
  },
  {
    id: "argentina",
    label: "Arjantin",
    imgSrc:
      "https://flagdownload.com/wp-content/uploads/Flag_of_Argentina-128x80.png",
    imgAlt: "Argentina",
  },
  {
    id: "uruguay",
    label: "Uruguay",
    imgSrc:
      "https://flagdownload.com/wp-content/uploads/Flag_of_Uruguay-128x85.png",
    imgAlt: "Uruguay",
  },
  {
    id: "germany",
    label: "Almanya",
    imgSrc:
      "https://flagdownload.com/wp-content/uploads/Flag_of_Germany-128x77.png",
    imgAlt: "Germany",
  },
  {
    id: "global",
    label: "Global",
    imgSrc:
      "https://flagdownload.com/wp-content/uploads/Flag_of_Union_of_South_American_Nations-128x85.png",
    imgAlt: "Global",
  },
  {
    id: "usa",
    label: "USA",
    imgSrc:
      "https://flagdownload.com/wp-content/uploads/Flag_of_United_States-128x67.png",
    imgAlt: "USA",
  },
  {
    id: "mena",
    label: "Mena",
    imgSrc:
      "https://flagdownload.com/wp-content/uploads/Flag_of_France-128x85.png",
    imgAlt: "Mena",
  },
];

export const platforms = [
  {
    id: "epic",
    label: "Epic Games",
    imgSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3xIBklpIj252IXfDAduAZV4nK7_WtQZMW7A&s",
    imgAlt: "Epic Games",
  },
  {
    id: "steam",
    label: "Steam",
    imgSrc:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Steam_icon_logo.svg/2048px-Steam_icon_logo.svg.png",
    imgAlt: "Steam",
  },
  {
    id: "rockstar",
    label: "Rockstar",
    imgSrc:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Rockstar_Games_Logo.svg/2226px-Rockstar_Games_Logo.svg.png",
    imgAlt: "Rockstar",
  },
];
export const filters = [
  {
    id: "preorder",
    label: "Ön Sipariş",
    imgSrc: "preorder-icon.png",
    imgAlt: "Ön Sipariş",
  },
];

export const devices = [
  {
    id: "windows",
    label: "Windows",
    imgSrc:
      "https://upload.wikimedia.org/wikipedia/commons/c/c7/Windows_logo_-_2012.png",
    imgAlt: "Windows",
  },
  {
    id: "xboxx",
    label: "Xbox X",
    imgSrc:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Xbox_one_logo.svg/2048px-Xbox_one_logo.svg.png",
    imgAlt: "Xbox X",
  },
  {
    id: "playstation",
    label: "PlayStation",
    imgSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXCUy7OXIuTjyXlIFMFaeMdC33HdIUMyq9gg&s",
    imgAlt: "PlayStation",
  },
  {
    id: "nintendo",
    label: "Nintendo Switch",
    imgSrc:
      "https://hackerx.org/wp-content/uploads/2019/01/nintendo-2-logo-png-transparent.png",
    imgAlt: "Nintendo Switch",
  },
  {
    id: "mac",
    label: "Mac",
    imgSrc:
      "https://w7.pngwing.com/pngs/374/156/png-transparent-apple-macbook-pro-logo-apple-logo-white-logo-black.png",
    imgAlt: "Mac",
  },
];

export const gameTypes = [
  {
    id: "action",
    label: "Aksiyon",
    imgSrc: "https://wallpapercave.com/wp/wp2605443.jpg",
    imgAlt: "Aksiyon",
  },
  {
    id: "adventure",
    label: "Macera",
    imgSrc:
      "https://www.gamespot.com/a/uploads/original/1552/15524586/3774263-613605-assassins-creed-valhalla-patch.jpg",
    imgAlt: "Macera",
  },
  {
    id: "racing",
    label: "Yarış",
    imgSrc: "https://i.ytimg.com/vi/GWH2uL3OzgQ/maxresdefault.jpg",
    imgAlt: "Yarış",
  },
  {
    id: "strategy",
    label: "Strateji",
    imgSrc:
      "https://play-lh.googleusercontent.com/MTqeao-7m_GdLz0krhI4CwhuEtTyYZheT7HdG9h9I4CNjIWyv-AXTIoFJpHDgECBvc4=w600-h300-pc0xffffff-pd",
    imgAlt: "Strateji",
  },
];
export const allProducts = [
  {
    id: 1,
    title: "God of War Ragnarok Global Steam CD Key",
    price: 1835.0,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: null,
  },
  {
    id: 2,
    title: "Black Myth: Wukong Steam CD Key",
    price: 1949.35,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 35,
  },
  {
    id: 3,
    title: "God of War Steam Key TR",
    price: 664.62,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 62,
  },
  {
    id: 4,
    title: "NASCAR Heat 5 Global Steam Key",
    price: 29.99,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 94,
  },
  {
    id: 5,
    title: "GTA 5 Premium Online Edition Rockstar Key",
    price: 396.0,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 70,
  },
  {
    id: 6,
    title: "Stick Fight: The Game",
    price: 9.9,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 90,
  },
  {
    id: 7,
    title: "Spider-Man: Miles Morales",
    price: 509.7,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 70,
  },
  {
    id: 8,
    title: "Horizon Zero Dawn",
    price: 240.0,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 85,
  },
  {
    id: 9,
    title: "Ghost of Tsushima",
    price: 1785.52,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 12,
  },
  {
    id: 10,
    title: "Assetto Corsa",
    price: 49.0,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 61,
  },
  {
    id: 11,
    title: "God of War Ragnarok Global Steam CD Key",
    price: 1835.0,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: null,
  },
  {
    id: 12,
    title: "Black Myth: Wukong Steam CD Key",
    price: 1949.35,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 35,
  },
  {
    id: 13,
    title: "God of War Steam Key TR",
    price: 664.62,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 62,
  },
  {
    id: 14,
    title: "NASCAR Heat 5 Global Steam Key",
    price: 29.99,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 94,
  },
  {
    id: 15,
    title: "GTA 5 Premium Online Edition Rockstar Key",
    price: 396.0,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 70,
  },
  {
    id: 16,
    title: "Stick Fight: The Game",
    price: 9.9,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 90,
  },
  {
    id: 17,
    title: "Spider-Man: Miles Morales",
    price: 509.7,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 70,
  },
  {
    id: 18,
    title: "Horizon Zero Dawn",
    price: 240.0,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 85,
  },
  {
    id: 19,
    title: "Ghost of Tsushima",
    price: 1785.52,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 12,
  },
  {
    id: 20,
    title: "Assetto Corsa",
    price: 49.0,
    photo: godOfWarRagnarok, // Dolduruldu
    discount: 61,
  },
];

export const notifications = [
  {
    id: 1,
    type: "başarı", // Bildirim türü: başarı
    content: '"Usta Kaşif" başarımını kazandınız!',
    isRead: false, // false: okunmamış, true: okunmuş
    timestamp: "2024-10-10 14:30:00", // Bildirimin alındığı tarih ve saat
  },
  {
    id: 2,
    type: "teklif", // Bildirim türü: teklif
    content: "Özel teklif: Oyun içi ürünlerde %50 indirim!",
    isRead: false,
    timestamp: "2024-10-09 16:45:00",
  },
  {
    id: 3,
    type: "güncelleme", // Bildirim türü: güncelleme
    content: "Favori oyununuz için yeni bir güncelleme mevcut!",
    isRead: true,
    timestamp: "2024-10-08 10:00:00",
  },
  {
    id: 4,
    type: "alışveriş", // Bildirim türü: oyun alışverişi
    content: "Tebrikler! Oyun alışınız başarıyla tamamlandı.",
    isRead: false,
    timestamp: "2024-10-10 15:00:00",
  },
];

export const supportSystem = {
  // Başlık: Cevaplama Süresi ve Süreç İşleyişi
  responseTimeAndProcess: {
    title: "Cevaplama Süresi ve Süreç İşleyişi",
    description:
      "Destek talepleriniz 09:00 - 00:00 aralığında uzmanlarımız tarafından cevaplanmaktadır.",
    processDetails: [
      "Her gün oluşturulan binlerce talebin getirdiği yoğunluk sebebiyle destek taleplerine en geç 24 saat içerisinde ilk cevap verilmektedir.",
      "Canlı destek operatörleri destek talebinize cevap veremez veya cevap süresini hızlandıramaz. Lütfen talep oluşturduktan sonra canlı desteğe bağlanmayın.",
    ],
  },

  // Başlık: Destek Talebi Oluşturma Gereksinimleri
  requestCreationRequirements: {
    title: "Destek Talebi Oluşturma Gereksinimleri",
    productSupport: [
      "Satıcılarımızdan aldığınız ürünler için destek talebi oluşturuyorsanız mutlaka geçerli bir video kaydı linki iletmelisiniz.",
      "https://files.fm/ gibi adreslere videonuzu yükleyerek linkini bize iletebilirsiniz.",
    ],
    digitalProductReturnPolicy: [
      "E-pin, CD-KEY, Hediye Kartı gibi dijital teslimatlı ürünler sözleşmelerimizde belirtildiği üzere iade edilemez.",
      "Üreticinin yaptığı bazı değişiklikler sebebiyle kodunuzun aktif edilmesinde problem yaşanma ihtimali düşükte olsa mevcuttur.",
      "Bu gibi durumların önüne geçmek adına E-Pin satın almadan önce mutlaka video kaydı başlatmanız gerekmektedir.",
      "Talep oluşturmanız durumunda bu video kaydı talep edilecektir.",
    ],
    spamRequestsWarning: [
      "Aynı konuyla alakalı birden fazla talep oluşturmak kesinlikle yasaktır.",
      "Bu işlem spam olarak algılanmanıza ve daha geç cevap almanıza neden olmaktadır.",
    ],
    evidenceSubmission:
      "Sistem yöneticileri sizden kanıt talebinde bulunduysa 24 saatlik süre içerisinde bu kanıtı iletmeniz gerekmektedir.",
  },

  // Başlık: Destek Sistemi Hakkında Bilgiler
  supportSystemInformation: {
    title: "Destek Sistemi Hakkında Bilgiler",
    description:
      "Destek taleplerinizi canlı desteğe kıyasla daha yetkili sistem yöneticileri cevaplamaktadır.",
    preOrderQuestions:
      "Sipariş öncesi sorular gibi basit konuları canlı desteğimize 7/24 sorabilir ve anlık cevap alabilirsiniz.",
    accountRecovery: [
      "Hesap geri alınma durumlarında geçerli bir videolu kanıtınız yoksa size destek sağlamamız mümkün değildir.",
      "Geçerli kanıt olması durumunda satıcının bakiyesi bulunuyorsa iadenizi destek talebiniz üzerinden biz gerçekleştiririz.",
      "Eğer satıcının bakiyesi bulunmuyor ise satıcıya mesaj göndererek yasal süreç hakkında bilgi aktarırız ve iade yapması gerektiğini belirtiriz.",
      "Eğer satıcı bu uyarıya rağmen iade yapmıyorsa konuyu adli mercilere taşımanız gerekmektedir.",
      "Adli mercilerin bizimle iletişime geçmesi durumunda satıcının tüm bilgilerini paylaşmaktayız.",
    ],
    misuseOfSupport: [
      "Mağaza üyelerin ilan veya değerlendirme için destek talebini kullanması yasaktır.",
      "Rapor sisteminin kullanılması zorunludur.",
    ],
    inappropriateMessages:
      "Destek talebi içerisinde küfür, hakaret veya argo içeren mesajlar hakkında şikayetçi olma hakkımızı gizli tutmayı tercih ediyoruz.",
  },
};

export const categories = [
  {
    category: "ilan_islemleri",
    subcategories: [
      "İlan Verme",
      "İlan Güncelleme",
      "İlan Silme",
      "İlan Ücreti",
    ],
  },
  {
    category: "epin_islemleri",
    subcategories: [
      "E-Pin Satın Alma",
      "E-Pin Kullanımı",
      "E-Pin İadesi",
      "E-Pin Geçersiz",
    ],
  },
  {
    category: "bakiye_islemleri",
    subcategories: [
      "Bakiye Yükleme",
      "Bakiye İadesi",
      "Yanlış Bakiye Yükleme",
      "Bakiye Transferi",
    ],
  },
  {
    category: "uyelik_islemleri",
    subcategories: [
      "Üyelik Aktivasyonu",
      "Şifre Değiştirme",
      "Üyelik Bilgisi Güncelleme",
      "Üyelik Silme",
    ],
  },
  {
    category: "kategori_islemleri",
    subcategories: [
      "Kategori Oluşturma",
      "Kategori Güncelleme",
      "Kategori Silme",
    ],
  },
  {
    category: "sikayet_oneri",
    subcategories: [
      "Şikayet Gönderme",
      "Öneri Gönderme",
      "Geri Bildirim Takibi",
    ],
  },
];
export const helpCenterData = {
  itemPurchase: {
    title: "İlan Satın Alma",
    questions: [
      {
        question: "İtemsatış Instagram veya Whatsapp'tan Satış Yapar Mı?",
        answer:
          "İtemsatış platformu dışında (Instagram, Whatsapp vb.) satış yapmaz. Tüm işlemler güvenli bir şekilde platform üzerinden gerçekleştirilmelidir.",
      },
      {
        question: "İlan siparişimin takibini nasıl yapabilirim?",
        answer:
          "İlan satın aldıktan sonra 'Siparişlerim' sekmesinden siparişinizin durumunu takip edebilirsiniz.",
      },
      {
        question:
          "Favori İlan Sistemi Nedir? Bir İlanı Nasıl Favorilerime Eklerim?",
        answer:
          "Beğendiğiniz ilanları ileride kolayca bulabilmek için favorilerinize ekleyebilirsiniz. İlan detay sayfasında bulunan 'Favorilere Ekle' butonuna tıklayarak bu işlemi yapabilirsiniz.",
      },
      {
        question: "İlan Alışveriş Sistemi Nasıl İşliyor?",
        answer:
          "Satıcı ve alıcı arasındaki işlemler platformun güvenlik sistemi altında gerçekleştirilir. Alıcı ürünü satın aldığında satıcıya bildirim gider ve teslim süreci başlar.",
      },
      {
        question: "Nasıl ilan alırım?",
        answer:
          "Platformda kayıtlı bir kullanıcı olarak beğendiğiniz bir ilanı sepete ekleyip, güvenli ödeme işlemi ile satın alabilirsiniz.",
      },
    ],
  },

  itemSale: {
    title: "İlan Satışı",
    questions: [
      {
        question: "Nasıl ilan verebilirim?",
        answer:
          "Hesabınıza giriş yaptıktan sonra 'İlan Ver' bölümüne giderek, satmak istediğiniz ürünün bilgilerini doldurup ilan oluşturabilirsiniz.",
      },
      {
        question: "İlan verirken dikkat edilmesi gerekenler nelerdir?",
        answer:
          "Ürününüzü doğru bir şekilde tanımlayın, fiyatlandırmayı dikkatlice yapın ve kaliteli görseller ekleyin.",
      },
      {
        question: "İlanım ne kadar süre yayında kalır?",
        answer:
          "İlanlar standart olarak 30 gün yayında kalır. Daha uzun süre yayında kalmasını istiyorsanız ilan yenileme paketlerinden faydalanabilirsiniz.",
      },
      {
        question: "İlanım satıldıktan sonra ne yapmalıyım?",
        answer:
          "Satış gerçekleştikten sonra alıcıya ürünü en kısa sürede teslim etmeniz gerekmektedir. Teslim sırasında yaşanan sorunları kayıt altına alın.",
      },
      {
        question: "Satıştan ne kadar komisyon alıyorsunuz?",
        answer:
          "İlan satışlarından platform belirli bir komisyon oranı alır. Bu oran ürün kategorisine göre değişiklik gösterebilir.",
      },
    ],
  },

  balanceTopUp: {
    title: "Para Yükleme",
    questions: [
      {
        question: "Hesabıma nasıl para yüklerim?",
        answer:
          "Hesabınıza para yüklemek için 'Cüzdan' menüsünden para yükleme seçeneklerinden birini seçerek işlem yapabilirsiniz.",
      },
      {
        question: "Hangi ödeme yöntemlerini kullanabilirim?",
        answer:
          "Kredi kartı, banka havalesi veya çeşitli dijital ödeme yöntemleri ile hesabınıza para yükleyebilirsiniz.",
      },
      {
        question: "Para yükleme işlemi neden başarısız oldu?",
        answer:
          "Ödeme yöntemiyle ilgili bir sorun olabilir. Kredi kartı bilgilerinizin doğruluğunu kontrol edin veya farklı bir yöntem deneyin.",
      },
      {
        question: "Yüklediğim para hesabıma ne zaman geçer?",
        answer:
          "Kredi kartı ve dijital ödemeler anında hesaba geçer. Havale/EFT gibi yöntemler banka işlem sürelerine bağlı olarak değişiklik gösterebilir.",
      },
    ],
  },

  balanceWithdrawal: {
    title: "Para Çekme",
    questions: [
      {
        question: "Hesabımdan nasıl para çekebilirim?",
        answer:
          "'Cüzdan' bölümünden para çekme talebi oluşturarak hesabınızdaki bakiyeyi banka hesabınıza aktarabilirsiniz.",
      },
      {
        question: "Para çekme işlemi ne kadar sürer?",
        answer:
          "Para çekme işlemi genellikle 1-3 iş günü içerisinde tamamlanır.",
      },
      {
        question: "Minimum para çekme limiti nedir?",
        answer:
          "Minimum para çekme limiti 50 TL'dir. Daha düşük tutarlarla çekim yapamazsınız.",
      },
      {
        question: "Para çekme işlemi başarısız oldu, ne yapmalıyım?",
        answer:
          "Banka bilgilerinizi kontrol edin ve doğru olduğundan emin olun. Sorun devam ederse destek talebi oluşturun.",
      },
    ],
  },

  refund: {
    title: "Para İadesi",
    questions: [
      {
        question: "Satın aldığım ürünü iade edebilir miyim?",
        answer:
          "Dijital ürünlerin iadesi mümkün değildir. Ancak, teslim edilmeyen ürünler için para iadesi talep edebilirsiniz.",
      },
      {
        question: "İade talebimi nasıl oluştururum?",
        answer:
          "Satın alma işleminizden sonra iade sebebinizi belirterek destek talebi oluşturabilirsiniz.",
      },
      {
        question: "İade süreci nasıl işliyor?",
        answer:
          "İade talepleri, satıcıdan alınan geri bildirimler ve kanıtlar doğrultusunda 3-5 iş günü içinde değerlendirilir.",
      },
      {
        question: "İade edilen tutar ne zaman hesabıma geçer?",
        answer:
          "İade edilen tutar, ödeme yönteminize bağlı olarak 3-7 iş günü içinde hesabınıza geçer.",
      },
    ],
  },

  profileAndAccount: {
    title: "Profil ve Hesap",
    questions: [
      {
        question: "Hesap bilgilerimi nasıl güncelleyebilirim?",
        answer:
          "Profil sekmesinden kişisel bilgilerinizi, şifre ve e-posta adresinizi kolayca güncelleyebilirsiniz.",
      },
      {
        question: "Hesabımın güvenliği için nelere dikkat etmeliyim?",
        answer:
          "Şifrenizi düzenli olarak değiştirin ve iki faktörlü doğrulamayı aktif hale getirin.",
      },
      {
        question: "Hesabım çalındı, ne yapmalıyım?",
        answer:
          "Destek talebi oluşturarak durumu bildirin. Hesabınızı kurtarma süreci için kimlik doğrulama adımlarını takip edin.",
      },
      {
        question: "Hesabımı nasıl silebilirim?",
        answer:
          "Profil ayarlarından 'Hesabımı Sil' seçeneğine tıklayarak hesabınızı silebilirsiniz.",
      },
    ],
  },

  security: {
    title: "Güvenlik",
    questions: [
      {
        question: "İki faktörlü kimlik doğrulama nedir?",
        answer:
          "İki faktörlü kimlik doğrulama, hesabınıza giriş yaparken ek güvenlik önlemi olarak kullanılır. SMS ya da uygulama aracılığıyla doğrulama kodu gönderilir.",
      },
      {
        question: "Hesap güvenliği için nelere dikkat etmeliyim?",
        answer:
          "Güçlü şifreler kullanın, hesap bilgilerinizi başkalarıyla paylaşmayın ve şüpheli e-postalara tıklamayın.",
      },
      {
        question: "Dolandırıcılık durumunda ne yapmalıyım?",
        answer:
          "Dolandırıcılık girişimleriyle karşılaştığınızda hemen destek talebi oluşturun ve kanıtlarınızı paylaşın.",
      },
      {
        question: "Güvenli ödeme nasıl yapılır?",
        answer:
          "Platform içi ödeme yöntemlerini kullanarak güvenli bir alışveriş yapabilirsiniz.",
      },
    ],
  },

  tips: {
    title: "İpuçları",
    questions: [
      {
        question: "Güvenli bir alışveriş için nelere dikkat etmeliyim?",
        answer:
          "Satıcıların değerlendirmelerini kontrol edin ve alışveriş sırasında kayıt tutun.",
      },
      {
        question: "İlanlar arasında nasıl daha kolay arama yapabilirim?",
        answer:
          "Filtreleme ve sıralama seçeneklerini kullanarak ilanları daha kolay bulabilirsiniz.",
      },
      {
        question: "Ürünleri fiyat karşılaştırması yaparak nasıl seçebilirim?",
        answer:
          "Benzer ürünler arasında fiyat ve özellik karşılaştırmaları yaparak en uygun olanı seçin.",
      },
    ],
  },

  corporate: {
    title: "Kurumsal",
    questions: [
      {
        question: "Oyun sitesi hakkında genel bilgi",
        answer:
          "Platformumuz, kullanıcılar arasında güvenli ve hızlı bir ticaret ortamı sunar.",
      },
      {
        question: "Müşteri hizmetleri çalışma saatleri",
        answer:
          "Müşteri hizmetlerimiz 09:00 - 00:00 saatleri arasında hizmet vermektedir.",
      },
      {
        question: "Platformun sunduğu avantajlar",
        answer:
          "Güvenli ödeme sistemi, hızlı işlem süreçleri ve kullanıcı dostu arayüz ile güvenli alışveriş sunar.",
      },
    ],
  },

  rulesAndBans: {
    title: "Kurallar & Yasaklar",
    questions: [
      {
        question: "Platformda neler yasaktır?",
        answer:
          "Dolandırıcılık girişimleri, uygunsuz dil kullanımı ve sahte ürün satışı platform kurallarına aykırıdır.",
      },
      {
        question: "Bir kural ihlaliyle karşılaşırsam ne yapmalıyım?",
        answer: "Destek ekibine durumu bildirebilir ve kanıt sunabilirsiniz.",
      },
      {
        question: "Hesap kapatma sebepleri nelerdir?",
        answer:
          "Sürekli kural ihlalleri, sahte ürün satışı ve dolandırıcılık girişimleri hesap kapatma nedenlerindendir.",
      },
      {
        question: "Bir ilan neden yayından kaldırılır?",
        answer:
          "Kurallara uymayan veya yanıltıcı bilgi içeren ilanlar yayından kaldırılabilir.",
      },
    ],
  },
};

export const HomePagecategories = [
  {
    category: "Oyun Anahtarları",
    categoryImage: "CD KEY",
  },
  {
    category: "Dijital Alışveriş Kodları",
    categoryImage: "Hediye Kartları",
  },
  {
    category: "Takipçi, Beğeni, Hesap",
    categoryImage: "Sosyal Medya",
  },
  {
    category: "Mobil Oyun Dünyası",
    categoryImage: "Mobil Oyunlar",
  },
  {
    category: "Hizmet Al",
    categoryImage: "Freelancer",
  },
  {
    category: "Reklam Satışı",
    categoryImage: "Reklam Satışı",
  },
  {
    category: "Çok Oyunculu Oyunlar",
    categoryImage: "MMO Oyunlar",
  },
  {
    category: "Boosting",
    categoryImage: "Boost Hizmetleri",
  },
  {
    category: "Tüm Oyun Platformları",
    categoryImage: "Platformlar",
  },
  {
    category: "Programlar ve Lisanslar",
    categoryImage: "Yazılım Ürünleri",
  },
  {
    category: "Rastgele Hesaplar",
    categoryImage: "Random Hesaplar",
  },
  {
    category: "Kategorisi Olmayan Her Şey!",
    categoryImage: "Diğer Ürün Satışları",
  },
  {
    category: "Discord Sunucu Reklamları",
    categoryImage: "Discord Sunucu Reklamları",
  },
  {
    category: "Şansını Dene",
    categoryImage: "Valorant Random Hesap",
  },
  {
    category: "TR Hesaplarına Yüklenir",
    categoryImage: "TR Pubg UC ID Yükleme",
  },
  {
    category: "Global Yükleme",
    categoryImage: "Global UC Top Up",
  },
  {
    category: "Diablo IV",
    categoryImage: "Diablo IV",
  },
  {
    category: "Steam Gift Card USD",
    categoryImage: "Steam Gift Card USD",
  },
  {
    category: "Steam Random Key",
    categoryImage: "Steam Random Key",
  },
  {
    category: "Telegram Reklam Satın Al",
    categoryImage: "Telegram Reklam Satın Al",
  },
  {
    category: "X Reklam Satın Al",
    categoryImage: "X Reklam Satın Al",
  },
  {
    category: "Instagram Reklam Satın Al",
    categoryImage: "Instagram Reklam Satın Al",
  },
  {
    category: "TikTok Reklam Satın Al",
    categoryImage: "TikTok Reklam Satın Al",
  },
  {
    category: "Twitch Reklam Satın Al",
    categoryImage: "Twitch Reklam Satın Al",
  },
  {
    category: "Kick Reklam Satın Al",
    categoryImage: "Kick Reklam Satın Al",
  },
  {
    category: "Youtube Reklam Satın Al",
    categoryImage: "Youtube Reklam Satın Al",
  },
  {
    category: "League of Legends - TR",
    categoryImage: "League of Legends - TR",
  },
  {
    category: "League of Legends - EU West",
    categoryImage: "League of Legends - EU West",
  },
  {
    category: "Derecesiz Hesap",
    categoryImage: "LoL Unranked Hesap",
  },
  {
    category: "Diğer Sunucular",
    categoryImage: "Diğer Sunucular",
  },
  {
    category: "Korea Riot Points",
    categoryImage: "Korea Riot Points",
  },
  {
    category: "North America Riot Points",
    categoryImage: "North America Riot Points",
  },
  {
    category: "United Kingdom Riot Points",
    categoryImage: "United Kingdom Riot Points",
  },
  {
    category: "LoL Hediye Ganimet",
    categoryImage: "LoL Hediye Ganimet",
  },
  {
    category: "Zula",
    categoryImage: "Zula",
  },
  {
    category: "Prime Hesap",
    categoryImage: "CS2 Hesap Satışı",
  },
  {
    category: "Skin Pazarı",
    categoryImage: "CS 2 Skin Pazarı",
  },
  {
    category: "Hesap ve Coin",
    categoryImage: "FC 24",
  },
  {
    category: "PK XD",
    categoryImage: "PK XD",
  },
  {
    category: "Fifa 23",
    categoryImage: "Fifa 23",
  },
  {
    category: "Rust",
    categoryImage: "Rust",
  },
  {
    category: "Wolfteam",
    categoryImage: "Wolfteam",
  },
  {
    category: "Rocket League",
    categoryImage: "Rocket League",
  },
  {
    category: "Forza Horizon",
    categoryImage: "Forza Horizon",
  },
  {
    category: "Apex Legends",
    categoryImage: "Apex Legends",
  },
  {
    category: "DarkOrbit",
    categoryImage: "DarkOrbit",
  },
  {
    category: "TikTok Servisleri",
    categoryImage: "TikTok",
  },
  {
    category: "Youtube Servisleri",
    categoryImage: "Youtube",
  },
  {
    category: "Spotify Servisleri",
    categoryImage: "Spotify",
  },
  {
    category: "X Servisleri",
    categoryImage: "Twitter",
  },
  {
    category: "Twitch Servisleri",
    categoryImage: "Twitch",
  },
  {
    category: "Telegram Servisleri",
    categoryImage: "Telegram",
  },
  {
    category: "Facebook Servisleri",
    categoryImage: "Facebook",
  },
  {
    category: "WhatsApp",
    categoryImage: "WhatsApp",
  },
  {
    category: "Üyelik",
    categoryImage: "Tinder",
  },
  {
    category: "Dlive",
    categoryImage: "Dlive",
  },
  {
    category: "Reddit",
    categoryImage: "Reddit",
  },
  {
    category: "Pinterest",
    categoryImage: "Pinterest",
  },
  {
    category: "Threads Servisleri",
    categoryImage: "Threads",
  },
  {
    category: "Sosyal Medya Programları",
    categoryImage: "Socifly",
  },
  {
    category: "Linkedin Servisleri",
    categoryImage: "LinkedIn",
  },
  {
    category: "Snapchat Servisleri",
    categoryImage: "Snapchat",
  },
  {
    category: "Bigo Live Elmas",
    categoryImage: "Bigo Live Elmas",
  },
  {
    category: "Kick",
    categoryImage: "Kick",
  },
  {
    category: "WeSing",
    categoryImage: "WeSing",
  },
  {
    category: "VK",
    categoryImage: "VK",
  },
  {
    category: "Firefly Live",
    categoryImage: "Firefly Live",
  },
  {
    category: "Likee",
    categoryImage: "Likee",
  },
];
