import React from "react";
import { Link } from "react-router-dom";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";

const FooterThirdLeftCard = () => {
  return (
    <div className="footer_third_left_card">
      {/* <button>Bize geri bildirim bırak</button> */}
      <Link to={""}>
        <BsTelephone />
        +99451 555 55 55
      </Link>
      <Link to={""}>
        <CiMail />
        destek@gamesatis.com
      </Link>
    </div>
  );
};

export default FooterThirdLeftCard;
