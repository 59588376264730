import React, { useEffect, useState } from "react";
import {
  FaBlog,
  FaClipboardList,
  FaColumns,
  FaCube,
  FaExchangeAlt,
  FaGifts,
  FaHandHoldingUsd,
  FaImage,
  FaKey,
  FaPortrait,
  FaShieldAlt,
  FaUniversity,
  FaUserAstronaut,
  FaUserShield,
} from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Avatar from "../images/messi.webp";
import { FaMobileScreen, FaShop } from "react-icons/fa6";

const ProfileDetailLeftContainer = ({ userObj }) => {
  console.log(userObj);
  const location = useLocation();
  const [hash, setHash] = useState("#ProfileSummary");

  useEffect(() => {
    setHash(location.hash);
  }, [location]);

  return (
    <div className="profile_detail_left_container">
      <div className="profile_detail_username_info_container">
        <div className="profile_detail_img">
          <img src={userObj?.avatar ? userObj?.avatar : Avatar} alt="Avatar" />
        </div>

        <div className="profile_detail_username_info">
          <p>
            {" "}
            <span>{userObj?.username}</span>
          </p>
          <p>
            {" "}
            <span> {userObj?.email}</span>
          </p>
          <p>
            Bakiye:
            <span> {userObj?.balance}</span>
          </p>
        </div>
      </div>

      <ul>
        <li>
          <Link
            to={"#ProfileSummary"}
            className={hash == "#ProfileSummary" ? "active" : ""}
          >
            <FaUserAstronaut /> Profilim
          </Link>
        </li>
        <li>
          <Link
            to={"#MembershipPackages"}
            className={hash == "#MembershipPackages" ? "active" : ""}
          >
            <FaShop /> Mağazam
          </Link>
        </li>
        {/* <li>
          <Link
            to={"#ProfileInfo"}
            className={hash == "#ProfileInfo" ? "active" : ""}
          >
            <FaUserShield /> Profil Bilgileri
          </Link>
        </li> */}
        <li>
          <Link to={"#Avatar"} className={hash == "#Avatar" ? "active" : ""}>
            <FaPortrait /> Avatar
          </Link>
        </li>
        <li>
          <Link
            to={"#CoverPhoto"}
            className={hash == "#CoverPhoto" ? "active" : ""}
          >
            <FaImage /> Kapak Fotoğrafı
          </Link>
        </li>
        {/* <li>
          <Link
            to={"#PasswordChange"}
            className={hash == "#PasswordChange" ? "active" : ""}
          >
            <FaKey /> Şifre Değiştir
          </Link>
        </li> */}
        {/* <li>
          <Link
            to={"#MailChange"}
            className={hash == "#MailChange" ? "active" : ""}
          >
            <MdAlternateEmail /> Mail Değiştir
          </Link>
        </li> */}
        {/* <li>
          <Link
            to={"#NumberChange"}
            className={hash == "#NumberChange" ? "active" : ""}
          >
            <FaMobileScreen /> Telefon Değiştir
          </Link>
        </li> */}
        <li>
          <Link
            to={"#AccountSecurity"}
            className={hash == "#AccountSecurity" ? "active" : ""}
          >
            <FaShieldAlt /> Hesap Güvenliği
          </Link>
        </li>
        <li>
          <Link
            to={"#BankAccounts"}
            className={hash == "#BankAccounts" ? "active" : ""}
          >
            <FaUniversity /> Banka Hesapları
          </Link>
        </li>
        <li>
          <Link
            to={"#AccessRecords"}
            className={hash == "#AccessRecords" ? "active" : ""}
          >
            <FaClipboardList /> Erişim Kayıtları
          </Link>
        </li>
        <li>
          <Link
            to={"#BalanceMovements"}
            className={hash == "#BalanceMovements" ? "active" : ""}
          >
            <FaHandHoldingUsd /> Bakiye Hareketleri
          </Link>
        </li>
        {/* <li>
          <Link
            to={"#BalanceCoupon"}
            className={hash == "#BalanceCoupon" ? "active" : ""}
          >
            <FaExchangeAlt /> Bakiye Kuponu
          </Link>
        </li> */}
        <li>
          <Link
            to={"#LinkedAccounts"}
            className={hash == "#LinkedAccounts" ? "active" : ""}
          >
            <FaBlog /> Bağlantılı Hesaplar
          </Link>
        </li>
        {/* <li>
          <Link
            to={"#GiftCenter"}
            className={hash == "#GiftCenter" ? "active" : ""}
          >
            <FaGifts /> Hediye Merkezi
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default ProfileDetailLeftContainer;
