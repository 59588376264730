import React from "react";
import { reviews } from "../mock/data";
import ImageProfile from "../images/avatar12.webp";
import { FaRegStar, FaStar } from "react-icons/fa";

const RecommendedShopAndProduct = () => {
  const StarRating = ({ rating }) => {
    const totalStars = 5;

    return (
      <div className="star_rating">
        {[...Array(totalStars)].map((_, index) => (
          <span key={index} className="star">
            {index < rating ? (
              <FaStar color="#e6670f" />
            ) : (
              <FaRegStar color="#e6670f" />
            )}
          </span>
        ))}
      </div>
    );
  };
  return (
    <div className="recomended_section container">
      {reviews.map((el, i) => (
        <div key={i} className="recomended_section_reviews">
          <img src={ImageProfile} alt="" />
          <div className="">
            <div className="recomended_name_and_rating">
              <h2>{el.game}</h2> <StarRating rating={el.rating} />
            </div>
            <div className="recomended_name_and_rating">
              <p>{el.message}</p> <span className="date">{el.date}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecommendedShopAndProduct;
