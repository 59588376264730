import React from "react";
import { Link } from "react-router-dom";
import HeaderFourthFirstImg from "../images/circleHeaderimg.jpg";
import { FaDiscord, FaInstagram, FaYoutube } from "react-icons/fa";
import { AiOutlinePercentage } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { IoNotificationsOutline } from "react-icons/io5";
import { HiMiniChatBubbleLeftRight } from "react-icons/hi2";
const HeaderFourthFirst = () => {
  return (
    <div className="header_fourth_first container">
      {/* <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link> */}
      {/* <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link>
        <Link to={''}><img src={HeaderFourthFirstImg} alt=''/></Link> */}
      <div className="header_fourth_icons">
        <Link>
          <FaDiscord />
        </Link>
        <Link>
          <FaInstagram />
        </Link>
        <Link>
          <FaYoutube />
        </Link>
        <Link>
          <FaXTwitter />
        </Link>
        <p>Azərbaycanın İlk Oyuncu Bazarı</p>
      </div>

      <div className="header_fourth_right">
        <ul>
          {/* <li>
            <Link to={"/messages"}>
              <HiMiniChatBubbleLeftRight className="header_seconds_first_element_chat" />
            </Link>
          </li> */}
          {/* <li>
            <Link to={"/notification"}>
              <IoNotificationsOutline className="header_seconds_first_element_notification" />
            </Link>
          </li> */}

          <li className="firsatlar">
            <Link to={""}>
              <AiOutlinePercentage /> Günün Fırsatları
            </Link>
          </li>
          <li>
            <Link to={""}>Blog</Link>
          </li>
          <li>
            <Link to={"/support"}>Destek Merkezi</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeaderFourthFirst;
