import React, { useState, useEffect } from "react";
import {
  FaCheckCircle,
  FaRocketchat,
  FaMobileAlt,
  FaShare,
  FaQuestion,
  FaMinus,
  FaPlus,
} from "react-icons/fa";
import AvatarProfile from "../images/avatar3.webp";
import CoverImage from "../images/cover-gamestore.jpg";
import { TbClockHour11 } from "react-icons/tb";
import {
  MdOutlineWorkspacePremium,
  MdFavoriteBorder,
  MdOutlineHeartBroken,
} from "react-icons/md";
import { IoCart } from "react-icons/io5";
import Avatar from "../images/avatar16.webp";
import axios from "axios";
import { baseUrl } from "../MAIN_API";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleLoginRegister, setRefreshed } from "../redux/MainReducer";
import { AiOutlineBars } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import { BiHourglass, BiStore } from "react-icons/bi";
import TestImg from "../images/fc-25-cover.jpeg";
const DetailPageSectionFirstContainer = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { refreshed } = useSelector((state) => state.Data);
  const [isFavorite, setIsFavorite] = useState(false);
  const selectedLang = localStorage.getItem("selectedLanguage");
  const activity = false;
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || {};
    if (storedFavorites[product.id]) {
      setIsFavorite(true);
    }
  }, [product.id]);

  const toggleFavorite = () => {
    const token = localStorage.getItem("access_token_game");
    if (token) {
      const storedFavorites =
        JSON.parse(localStorage.getItem("favorites")) || {};
      if (storedFavorites[product.id]) {
        removeFromFavorites();
      } else {
        addToFavorites();
      }
    } else {
      dispatch(handleLoginRegister());
    }
  };

  const addToFavorites = () => {
    const data = {
      product: product.id,
    };
    axios
      .post(`${baseUrl}product/favorite-create`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_game")}`,
        },
      })
      .then((resp) => {
        if (resp.status === 201) {
          const storedFavorites =
            JSON.parse(localStorage.getItem("favorites")) || {};
          storedFavorites[product.id] = true;
          localStorage.setItem("favorites", JSON.stringify(storedFavorites));
          setIsFavorite(true);
          Swal.fire({
            title: "Favori ilanına eklendi",
            icon: "success",
            confirmButtonText: "OK",
            width: "400px",
          }).then(() => {
            navigate(`/${product.name}/${product.id}`);
            if (!refreshed) {
              dispatch(setRefreshed());
              window.location.reload();
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error adding to favorites", error);
      });
  };
  console.log(product);

  const removeFromFavorites = () => {
    axios
      .delete(`${baseUrl}product/favorite-delete/${product.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_game")}`,
        },
      })
      .then((resp) => {
        if (resp.status === 200) {
          const storedFavorites =
            JSON.parse(localStorage.getItem("favorites")) || {};
          delete storedFavorites[product.id];
          localStorage.setItem("favorites", JSON.stringify(storedFavorites));
          setIsFavorite(false);
          Swal.fire({
            title: "Favorilerden çıkarıldı",
            icon: "success",
            confirmButtonText: "OK",
            width: "400px",
          }).then(() => {
            navigate(`/${product.name}/${product.id}`);
            if (!refreshed) {
              dispatch(setRefreshed());
              window.location.reload();
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error removing from favorites", error);
      });
  };

  return (
    // <div className="detail_page_section_first_container container">
    //   <div className="detail_page_product_container">
    //     <div className=" detail_page_first_section_image_block  ">
    //       <div className="detail_page_section_first_img">
    //         <img src={product?.photo} alt={product?.title} />
    //       </div>
    //       <div className="views">
    //         <BsEye />{" "}
    //         <p>
    //           24 Saat içinde <span>110</span> kişi görüntüledi
    //         </p>
    //       </div>
    //     </div>
    //     <div className="detail_page_section_first_content detail_page_section_first_card">
    //       <div className="title-product-id">
    //         <h3>{product?.title}</h3>
    //         <p>#{product?.product_id}</p>
    //       </div>
    //       <div className="detail_page_price">
    //         <div className="detail_page_price_inner">
    //           <p className="discount-price">
    //             {" "}
    //             {3000} {selectedLang === "az" ? "₼" : "₺"}
    //           </p>
    //           <p className="product_price">
    //             {" "}
    //             {product?.price} {selectedLang === "az" ? "₼" : "₺"}
    //           </p>
    //         </div>
    //         <div className="product_delivery">
    //           <span>
    //             <TbClockHour11 />{" "}
    //             <p>
    //               tahmini teslimat süresi: <span>24 saat</span>
    //             </p>
    //           </span>
    //           <span>
    //             <TbClockHour11 />{" "}
    //             <p>
    //               teslimat saatleri: <span>00:00-23:59</span>
    //             </p>
    //           </span>
    //         </div>
    //       </div>
    //       <div className="product_card_details_footer">
    //         <div className="product_count_selling">
    //           <div className="detail_page_in_stock">
    //             <p>stok:</p>
    //             <p>100+</p>
    //           </div>
    //           <div className="count_buttons">
    //             <button>
    //               <FaMinus />
    //             </button>
    //             <span>1</span>
    //             <button>
    //               <FaPlus />
    //             </button>
    //             <button className="add_to_basket">
    //               <IoCart /> Satın Al
    //             </button>
    //             <button onClick={toggleFavorite}>
    //               {isFavorite ? <MdOutlineHeartBroken /> : <MdFavoriteBorder />}
    //             </button>
    //           </div>
    //           <div className="detail_page_adding_time">
    //             <p>Eklenme Tarihi: 17.09.2024</p>
    //             <p>Güncellenme Tarihi: 20.09.2024</p>
    //           </div>
    //         </div>
    //         <div className="detail_page_section_first_content_buttons">
    //           {/* <button><FaQuestion /> Soru Sor</button> */}

    //           {/* <button>
    //             <FaShare /> Paylaş
    //           </button> */}
    //         </div>
    //         <p>Kalan süre : 18 gün 18 saat 10 dakika 30 saniye</p>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="detail_page_section_first_content_two">
    //     <h4>SATICI BİLGİLERİ</h4>
    //     <div className="detail_page_seller_cover_img">
    //       <img src={CoverImage} alt="" />
    //       <div className="detail_page_seller_profile_img">
    //         <img src={product?.seller?.cover} alt="" />
    //       </div>
    //     </div>
    //     <div className="detail_page_section_first_content_two_header">
    //       <div className="detail_page_section_first_content_two_header_inner_container">
    //         <div className="detail_page_section_first_content_two_header_inner">
    //           <div className="detail_page_section_first_content_two_header_inner_content">
    //             <div className="detail_page_section_first_content_two_header_inner_content_inner">
    //               {/* <img
    //                 src={
    //                   product?.seller?.cover ? product?.seller?.cover : Avatar
    //                 }
    //                 alt={product?.seller?.name}
    //               /> */}
    //               <h5>{product?.seller?.name}</h5>
    //               <div className="all_shops_card_footer_buttons">
    //                 {/* <div style={{ display: "none" }}><BiLike />62276</div>
    //                                     <div>182</div> */}
    //                 <div>555</div>
    //                 <div>1</div>
    //                 <div></div>
    //                 <div></div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <p className="join_date_seller">üyelik tarihi: 2024.12.12</p>
    //         <div className="detail_page_store_link">
    //           <p>son görülme 2 saat önce</p>
    //           <Link to={"/store"}>
    //             {" "}
    //             <BiStore /> <span>mağazaya git</span>
    //           </Link>
    //         </div>

    //         <p className="successfull_sales">
    //           <span>Başarılı İşlem</span> <span>37/(40)</span>
    //         </p>
    //         <div className="successfull_sales_stick_bar">
    //           <span
    //             className="successfull_sales_active_stick_bar"
    //             style={{ "--i": "70%" }}
    //           ></span>
    //         </div>
    //       </div>

    //       {/* <button>
    //         <AiOutlineBars />
    //         Satıcının tüm ilanları
    //       </button> */}
    //       <div className="detail_page_section_first_content_two_footer">
    //         <div>{product?.price} TL İlan Ücreti</div>
    //         <div>{product?.stock} Stok Sayısı</div>
    //       </div>
    //       <div className="detail_page_section_first_content_two_chat_sms">
    //         <button>
    //           <FaRocketchat /> Sohbet et
    //         </button>
    //         <button>
    //           <FaMobileAlt /> Sms gönder
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="container details_page_product_container">
      <div className="details_page_product_image_container">
        <div className="details_page_product_image">
          <img src={TestImg} alt={product?.title} />
        </div>
      </div>
      <div className="details_page_product_about_container">
        <div className="details_page_product_about">
          <h3>{product?.title}</h3>
          <p>
            <span>ürün açıklaması: </span>
            {product?.description}
          </p>
          <div className="product_delivery">
            <span>
              <TbClockHour11 />{" "}
              <p>
                tahmini teslimat süresi: <span>24 saat</span>
              </p>
            </span>
            <span>
              <TbClockHour11 />{" "}
              <p>
                teslimat saatleri: <span>00:00-23:59</span>
              </p>
            </span>
          </div>
        </div>
        <div className="details_page_product_seller_container">
          <h2>satıcı bilgileri</h2>
          <div className="details_page_product_seller_inner">
            <div className="details_page_product_seller">
              <div className="details_page_product_seller_image">
                <img
                  // src={product?.seller?.cover ? product?.seller?.cover : Avatar}
                  src={Avatar}
                  alt={product?.seller?.name}
                />
              </div>
              <div className="details_page_product_seller_about">
                <h5>
                  {product?.seller?.name}
                  {activity ? (
                    <div className="seller_activity">
                      <span></span>
                    </div>
                  ) : (
                    <div className="seller_activity_offline">
                      <span>10dk önce aktifdi</span>
                    </div>
                  )}
                </h5>
                <p>Mağazanın Diğer Ürünleri (517)</p>
                <Link to={"/store"}>mağazaya git</Link>
              </div>
            </div>

            <div className="details_page_product_seller_ratings">
              <span>200 başarılı işlem</span>
              <span>ilan ücreti: 200tl</span>
              <span>satıcı puanı (9.7)</span>
            </div>
          </div>
        </div>
        <div className="details_page_product_views_and_about">
          <div className="details_page_product_views_and_about_card">
            <span>ID: </span>
            <span>#{product?.product_id}</span>
          </div>
          <div className="details_page_product_views_and_about_card">
            {" "}
            <span>Görünteleme</span>
            <span className="details_page_product_views_and_about_card_view_icon">
              {" "}
              <BsEye /> 110K
            </span>
          </div>
          <div className="details_page_product_views_and_about_card">
            <span>Güncellenme:</span>
            <span>10:10:2024</span>
          </div>
          <div className="details_page_product_views_and_about_card">
            <span>stok:</span>
            <span>100+</span>
          </div>
          <div className="details_page_product_views_and_about_card">
            <span>kategori:</span>
            <span>{product?.game_category?.title}</span>
          </div>
        </div>
      </div>
      <div className="details_page_product_addtocard_container">
        <h2>Sipariş özeti</h2>
        <div className="details_page_product_addto_card">
          <div className="detail_page_price_inner">
            <p className="discount-price">
              ürün fiyatı:
              <span>
                {3000} {selectedLang === "az" ? "₼" : "₺"}
              </span>
            </p>
            <p className="product_price">
              indirimli fiyat
              <span>
                {product?.price} {selectedLang === "az" ? "₼" : "₺"}
              </span>
            </p>
          </div>
        </div>
        <div className="count_buttons">
          <div className="count_buttons_inner">
            <span>Adet:</span>
            <button>
              <FaMinus />
            </button>
            <span>1</span>
            <button>
              <FaPlus />
            </button>
          </div>
          <button className="addfavorite" onClick={toggleFavorite}>
            {isFavorite ? (
              <span>
                favorilerden çıkar <MdOutlineHeartBroken />
              </span>
            ) : (
              <span>
                Favorilere ekle <MdFavoriteBorder />{" "}
              </span>
            )}
          </button>
        </div>
        <div className="detail_page_price_result">
          toplam Fiyat: <span>200 tl</span>
        </div>
        <button className="add_to_basket">
          <IoCart /> Satın Al
        </button>
        <div className="detail_page_section_first_content_two_chat_sms">
          <button>
            <FaRocketchat /> Sohbet et
          </button>
          <button>
            <FaMobileAlt /> Sms gönder
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailPageSectionFirstContainer;
