import React from "react";
import { FaCrown, FaHeart, FaMeteor, FaStar } from "react-icons/fa";

const MainThirdButtons = ({ activeFourthButton, onButtonClick }) => {
  return (
    <div className="main_second_buttons main_second_buttons_home container">
      <button
        className={
          activeFourthButton === "Valorant" ? "main_second_active_button" : ""
        }
        onClick={() => onButtonClick("Valorant")}
      >
        <FaHeart /> Valorant
      </button>

      <button
        className={
          activeFourthButton === "Vitrin İlanları"
            ? "main_second_active_button"
            : ""
        }
        onClick={() => onButtonClick("Vitrin İlanları")}
      >
        <FaCrown /> Vitrin İlanları
      </button>

      <button
        className={
          activeFourthButton === "Yeni İlanlar"
            ? "main_second_active_button"
            : ""
        }
        onClick={() => onButtonClick("Yeni İlanlar")}
      >
        <FaMeteor /> Yeni İlanlar
      </button>

      <button
        className={
          activeFourthButton === "Favori İlanlar"
            ? "main_second_active_button"
            : ""
        }
        onClick={() => onButtonClick("Favori İlanlar")}
      >
        <FaStar /> Favori İlanlar
      </button>
    </div>
  );
};

export default MainThirdButtons;
