import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import NewSupportRequest from "./NewSupportRequest";

const SupportRequest = () => {
  const location = useLocation();
  const [hash, setHash] = useState("#ProfileSummary");

  useEffect(() => {
    setHash(location.hash);
  }, [location]);

  return (
    <>
      <div className="support_main_container">
        <div className="support_left_side">
          <h2>Destek Merkezi</h2>
          <p>
            Destek talepleriniz 10:00 - 23:00 saatleri arasında işleme alınır ve
            en geç 48 saat içinde yanıtlanır. İlan ya da satın aldığınız
            ürünlerle ilgili şikayetlerinizde fotoğraf veya belge ile
            detaylandırmanız gerekmektedir.
          </p>
        </div>
        <div className="support_right_side">
          <Link
            to={"#new-support-request"}
            className={
              hash == "#new-support-request" ? "support_active_button" : ""
            }
          >
            <FaPlus /> Yeni Destek talebi
          </Link>
          <Link
            to={"#support-details"}
            className={
              hash == "#support-details" ? "support_active_button" : ""
            }
          >
            <FaPlus /> Talep oluşturma süreci
          </Link>
          <Link
            to={"#live-support"}
            className={hash == "#live-support" ? "support_active_button" : ""}
          >
            <FaPlus /> Canlı Destek
          </Link>
        </div>
      </div>
      <div className="new_support_main_container">
        {hash == "#new-support-request" && <NewSupportRequest />}
        {hash == "#support-details" && <NewSupportRequest />}
        {hash == "#live-support" && <NewSupportRequest />}
      </div>
    </>
  );
};

export default SupportRequest;
