import React, { useEffect, useState } from "react";
import "../css/account.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserObj } from "../actions/MainAction";
import { useLocation } from "react-router-dom";
import ProfileSummary from "./control-center-sections/ProfileSummary";
import MembershipPackages from "./control-center-sections/MembershipPackages";
import ProfileInfo from "./control-center-sections/ProfileInfo";
import PasswordChange from "./control-center-sections/PasswordChange";
import Avatar from "./control-center-sections/Avatar";
import CoverPhoto from "./control-center-sections/CoverPhoto";
import MailChange from "./control-center-sections/MailChange";
import NumberChange from "./control-center-sections/NumberChange";
import AccountSecurity from "./control-center-sections/AccountSecurity";
import BankAccounts from "./control-center-sections/BankAccounts";
import AccessRecords from "./control-center-sections/AccessRecords";
import BalanceMovements from "./control-center-sections/BalanceMovements";
import BalanceCoupon from "./control-center-sections/BalanceCoupon";
import LinkedAccounts from "./control-center-sections/LinkedAccounts";
import GiftCenter from "./control-center-sections/GiftCenter";

const ProfileDetailRightContainer = ({ userObj }) => {
  // const {userObj}=useSelector(state=>state.Data)
  // const dispatch=useDispatch()
  // console.log(userObj);

  // useEffect(()=>{
  //     dispatch(getUserObj())
  // },[dispatch])

  const location = useLocation();
  const [hash, setHash] = useState("#ProfileSummary");

  useEffect(() => {
    setHash(location.hash);
  }, [location]);

  return (
    <>
      {hash == "#ProfileSummary" && <ProfileSummary userObj={userObj} />}
      {hash == "#MembershipPackages" && (
        <MembershipPackages userObj={userObj} />
      )}
      {/* {hash == "#ProfileInfo" && <ProfileInfo userObj={userObj} />} */}
      {/* {hash == "#PasswordChange" && <PasswordChange userObj={userObj} />} */}
      {hash == "#Avatar" && <Avatar userObj={userObj} />}
      {hash == "#CoverPhoto" && <CoverPhoto userObj={userObj} />}
      {/* {hash == "#MailChange" && <MailChange userObj={userObj} />} */}
      {/* {hash == "#NumberChange" && <NumberChange userObj={userObj} />} */}
      {hash == "#AccountSecurity" && <AccountSecurity userObj={userObj} />}
      {hash == "#BankAccounts" && <BankAccounts userObj={userObj} />}
      {hash == "#AccessRecords" && <AccessRecords userObj={userObj} />}
      {hash == "#BalanceMovements" && <BalanceMovements userObj={userObj} />}
      {/* {hash == "#BalanceCoupon" && <BalanceCoupon userObj={userObj} />} */}
      {hash == "#LinkedAccounts" && <LinkedAccounts userObj={userObj} />}
      {/* { hash == "#GiftCenter" && <GiftCenter userObj={userObj}/> } */}
    </>
  );
};

export default ProfileDetailRightContainer;
