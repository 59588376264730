import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaBars,
  FaCaretDown,
  FaGift,
  FaShoppingBag,
  FaLaptopCode,
  FaStore,
} from "react-icons/fa";
import { FaKey, FaXmark } from "react-icons/fa6";
import { TbCategoryFilled } from "react-icons/tb";
import { FaBasketShopping } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { categoryModalFunc } from "../redux/MainReducer";
import CategoryModal from "./modals/CategoryModal";
import HeaderSecondsButtons from "./HeaderSecondsButtons";
import AddAnnounce from "./AddAnnounce";
import { toggle } from "../redux/MainReducer";
import HeaderSecondInp from "./HeaderSecondInp";
const HeaderThirdContainer = ({ isSearchVisible, toggleSearchVisibility }) => {
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const { categoryModal } = useSelector((state) => state.Data);

  const barsHandle = () => {
    setIsActive(!isActive);
  };
  const isToggled = useSelector((state) => state.Data.isToggled);
  console.log(isToggled);
  return (
    <nav className="header_nav">
      <div className="container header_third">
        <ul className={`header_third_container ${!isToggled ? "active" : ""}`}>
          <div className="closedButton" onClick={() => dispatch(toggle())}>
            <FaXmark />
          </div>
          <li
            // onClick={() => {
            //   dispatch(toggle());
            //   dispatch(categoryModalFunc());
            // }}
          >
            <Link to={'/categories'}>
              <TbCategoryFilled className="header_third_nav_icon" />{" "}
              <span>
                <span>Kategoriler</span>
              </span>
            </Link>
          </li>
          <li onClick={() => dispatch(toggle())}>
            <Link to={"/game-shop"}>
              <FaBasketShopping className="header_third_nav_icon" /> Oyuncu
              Pazarı
            </Link>
          </li>
          <li onClick={() => dispatch(toggle())}>
            <Link to={"/giveaway"}>
              <FaGift className="header_third_nav_icon" /> Çekilişler
            </Link>
          </li>
          <li onClick={() => dispatch(toggle())}>
            <Link to={"/shops"}>
              <FaStore className="header_third_nav_icon" /> Mağazalar
            </Link>
          </li>
          <li onClick={() => dispatch(toggle())}>
            <Link to={"/giveaway"}>
              <FaGift className="header_third_nav_icon" /> Pubg
            </Link>
          </li>
          <li onClick={() => dispatch(toggle())}>
            <Link to={"/shops"}>
              <FaStore className="header_third_nav_icon" /> Valorant
            </Link>
          </li>
          <li onClick={() => dispatch(toggle())}>
            <Link to={"/shops"}>
              <FaStore className="header_third_nav_icon" /> Mağazalar
            </Link>
          </li>
          <li onClick={() => dispatch(toggle())}>
            <Link to={"/shops"}>
              <FaStore className="header_third_nav_icon" /> Mağazalar
            </Link>
          </li>
          <li onClick={() => dispatch(toggle())}>
            <Link to={"/cd-keys"}>
              <FaKey className="header_third_nav_icon" /> CD Key
            </Link>
          </li>
          {/* <li><Link to={'/game-sale-project'}><FaLaptopCode className='header_third_nav_icon' /> Yazılım</Link></li> */}
        </ul>

        <FaBars onClick={() => dispatch(toggle())} className="bars_icon" />
        <div className="mobile-input">
          <HeaderSecondInp />
        </div>
        {/* <HeaderSecondsButtons
                    isSearchVisible={isSearchVisible}
                    toggleSearchVisibility={toggleSearchVisibility}
                /> */}
        {/* <AddAnnounce /> */}
      </div>
      {categoryModal && <CategoryModal />}
    </nav>
  );
};

export default HeaderThirdContainer;
