import OtherHeader from "../components/OtherHeader";
import FooterSecondContainer from "../components/FooterSecondContainer";
import FooterThirdContainer from "../components/FooterThirdContainer";
import FooterEndContainer from "../components/FooterEndContainer";
import EmptyNotification from "../components/EmptyNotification";
import NotificationContainer from "../components/NotificationContainer";

const Notification = ({ otherPageBanner, banners }) => {
  const notification = "not";
  return (
    <div>
      <OtherHeader
        otherHeaderPagesLink="Çekilişler"
        banners={banners}
        otherPageBanner={otherPageBanner}
      />
      <main className=" notification_main_section container">
        {!notification && <EmptyNotification />}
        {notification && <NotificationContainer />}
      </main>
      <footer>
        <div className="footer_parent_container">
          {/* <FooterFirstContainer /> */}
          <FooterSecondContainer />
          <FooterThirdContainer />
        </div>
        <FooterEndContainer />
      </footer>
    </div>
  );
};

export default Notification;
