import React from "react";

const FooterEndContainer = () => {
  let date = new Date().getFullYear();
  return (
    <div className="footer_end_container">
      <p> © - {date} | Cyberpazar</p>
    </div>
  );
};

export default FooterEndContainer;
