import OtherHeader from "../components/OtherHeader";
import FooterSecondContainer from "../components/FooterSecondContainer";
import FooterThirdContainer from "../components/FooterThirdContainer";
import FooterEndContainer from "../components/FooterEndContainer";
import { useState } from "react";
import HelpCenter from "../components/HelpCenter";
import SupportRequest from "../components/SupportRequest";

const Support = ({ otherPageBanner, banners }) => {
  const [activeButton, setActiveButton] = useState("2");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    localStorage.setItem("activeButton", buttonName);
  };
  const renderComponent = () => {
    switch (activeButton) {
      case "1":
        return <HelpCenter />;
      case "2":
        return <SupportRequest />;
      //   case "3":
      //     return <Achievements />;

      default:
        return <HelpCenter />;
    }
  };
  return (
    <div>
      <OtherHeader
        otherHeaderPagesLink="Çekilişler"
        banners={banners}
        otherPageBanner={otherPageBanner}
      />

      <div className="support_container_navbar container">
        <button
          className={activeButton === "2" ? "support_active_button" : ""}
          onClick={() => handleButtonClick("2")}
        >
          Destek Talebi
        </button>
        <button
          className={activeButton === "1" ? "support_active_button" : ""}
          onClick={() => handleButtonClick("1")}
        >
          Yardım Merkezi
        </button>
      </div>
      <div className="support_content_container container">
        {renderComponent()}
      </div>
      <footer>
        <div className="footer_parent_container">
          {/* <FooterFirstContainer /> */}
          <FooterSecondContainer />
          <FooterThirdContainer />
        </div>
        <FooterEndContainer />
      </footer>
    </div>
  );
};

export default Support;
