import React from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { IoLogoDiscord } from "react-icons/io5";
import { RiYoutubeLine } from "react-icons/ri";
import { AiOutlinePercentage } from "react-icons/ai";
import { LuPackage2 } from "react-icons/lu";
import Video from "../videos/video.mp4";
import Banner from "../images/banner-img.png";
const HeaderFirstContainer = () => {
  return (
    <div className="header_first_container">
      <div className="bg_shadow_header_first">
        {/* <video autoPlay muted loop id="background-video">
          <source src={Video} type="video/mp4" />
          Sizin brauzeriniz videonu dəstəkləmir.
        </video> */}
        <img src={Banner} alt="" id="background-video" />

        <div className="header_first_list_container container">
          {/* <ul>
                        <li><Link to={''}><FaInstagram className='header_social_icon header_social_instagram_icon' /></Link></li>
                        <li><Link to={''}><FaFacebookF className='header_social_icon header_social_facebook_icon' /></Link></li>
                        <li><Link to={''}><IoLogoDiscord className='header_social_icon header_social_discord_icon' /></Link></li>
                        <li><Link to={''}><RiYoutubeLine className='header_social_icon header_social_youtube_icon' /></Link></li>
                    </ul>
                    <ul>
                        <li><Link to={''}><AiOutlinePercentage /> Günün Fırsatları</Link></li>
                        <li><Link to={''}><LuPackage2 /> Mağaza Paketleri</Link></li>
                    </ul> */}
        </div>
      </div>
    </div>
  );
};

export default HeaderFirstContainer;
