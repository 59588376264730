export const initialState = {
  loginRegisterModal: false,
  userObj: {},
  highlightProducts: [],
  products: [],
  productEl: {},
  myAnnouncements: [],
  shopCreateModal: false,
  myFavories: [],
  refreshed: false,
  loader: false,
  shopList: [],
  isToggled: true,
  auctionList: [],
  auctionEl: {},
  newsList: [],
  newsEl: {},

  announceCreateModal: false,

  productListFilter: [],
  categoryList: [],
  gameCategoryFilterList: [],
  filterProducts: [],

  productsSearchList: [],
  categoryModal: false,
  shopProducts: [],
  popularProducts: [],
};
