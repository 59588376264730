import React from "react";
import { BiLike } from "react-icons/bi";
import { FaRegEdit, FaStar } from "react-icons/fa";
import { IoIosChatbubbles } from "react-icons/io";
import { RiUserFollowFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import Avatar from "../images/avatar15.webp";
const ProfileDetailMainHeader = () => {
  const { userObj } = useSelector((state) => state.Data);
  console.log(userObj);
  return (
    <div className="shop_profile_section_first_header">
      <div className="shop_profile_section_first_header_all">
        <div className="shop_profile_section_first_header_left">
          <div className="shop_profile_section_first_header_left_seller_profile_img">
            {userObj?.avatar ? (
              <img src={userObj?.avatar} alt="" />
            ) : (
              <img src={Avatar} alt="" />
            )}
          </div>

          <div className="shop_profile_section_first_header_left_content">
            {/* <span>4 saat önce aktifti.</span> */}
            <h2>
              {userObj?.username}{" "}
              <Link to="/control-center#ProfileSummary" title="profili duzenle">
                <CiEdit />
              </Link>
            </h2>
            <div className="all_shops_card_footer_buttons">
              <div style={{ display: "none" }}>
                <BiLike />
                62276
              </div>
              <div>182</div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="all_shops_card_middle ">
              {/* <Link to="/control-center#ProfileInfo">
              <FaRegEdit /> Profilini Düzənlə
            </Link> */}
            </div>
          </div>
        </div>
        <div className="shop_profile_section_first_header_right">
          Kayıt Tarihi : 05.01.2024 | 20:46
        </div>
      </div>

      {/* <div className="shop_profile_section_first_header_right">
        <span>Üyelik Tarihi : 6 Şubat 2021</span>
        <div className="shop_profile_section_first_header_right_star">
          <FaStar className="shop_profile_section_first_header_right_star_icon" />
          <p>
            <span>9.5</span> / 10
          </p>
        </div>
        <p className="shop_profile_section_first_header_right_second">
          Toplam 612 mağaza değerlendirmesi
        </p>
        <p className="shop_profile_section_first_header_right_end">
          1,902 Başarılı İşlem
        </p>
      </div> */}

    </div>
  );
};

export default ProfileDetailMainHeader;
