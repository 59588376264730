import React, { useRef } from "react";
import {
  FaBars,
  FaCoins,
  FaGift,
  FaHeart,
  FaHome,
  FaRegCreditCard,
  FaSignOutAlt,
  FaStore,
  FaUser,
  FaUserCircle,
} from "react-icons/fa";
import { IoHelp, IoNotificationsOutline } from "react-icons/io5";
import { MdCategory } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { RiCustomerService2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { handleLoginRegister, toggle } from "../redux/MainReducer";
import { categoryModalFunc } from "../redux/MainReducer";
import { FaBasketShopping } from "react-icons/fa6";
import { HiOutlineSupport } from "react-icons/hi";
import Avatar from "../images/avatar3.webp";

const FooterNavbar = () => {
  const profileDetails = useRef();

  const dispatch = useDispatch();
  const { userObj } = useSelector((state) => state.Data);
  const navigate = useNavigate();

  let getAccess = localStorage.getItem("access_token_game");
  const exitBtn = () => {
    localStorage.removeItem("access_token_game");
    localStorage.removeItem("loggedInEmail");
    window.location.reload();
  };
  const myAnnouncementsBtn = () => {
    navigate("/my-announcements");
  };

  const myFavoriesBtn = () => {
    navigate("/my-favories");
  };

  const detailProfileHandle = (username) => {
    navigate(`/profile/${username}`);
  };
  const avatarToggleFunc = () => {
    profileDetails.current.classList.toggle("active");
  };
  return (
    <>
      <div className="footer-navbar-sticky">
        <div className="sticky-navbar-box">
          <Link to={"/"}>
            {" "}
            <FaHome /> <span>Anasayfa</span>
          </Link>
          <button onClick={() => dispatch(toggle())}>
            {" "}
            <FaBars /> <span>Menyu</span>
          </button>
          <Link to={"/categories"}>
            {" "}
            <FaGift /> <span>Kategoriler</span>
          </Link>

          {getAccess ? (
            <button onClick={() => detailProfileHandle(userObj?.username)}>
              {" "}
              <FaUser /> <span>Hesabım</span>
            </button>
          ) : (
            <button onClick={() => dispatch(handleLoginRegister())}>
              {" "}
              <FaUser /> <span>Giriş</span>
            </button>
          )}
          <Link className="notifcation_mobil_icon_link" to={"/notification"}>
              <IoNotificationsOutline  />
              <span>Bildirişlər</span>
              <span className="notifcation_mobil_icon_count">0</span>
            </Link>
          <Link to={"/"}>
            {" "}
            <RiCustomerService2Line /> <span>Dəstək</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default FooterNavbar;
