import OtherHeader from "../components/OtherHeader";
import FooterSecondContainer from "../components/FooterSecondContainer";
import FooterThirdContainer from "../components/FooterThirdContainer";
import FooterEndContainer from "../components/FooterEndContainer";
import { HomePagecategories } from "../mock/data";
import "../css/categories.css";
import Avatar from "../images/pubg-mobil-60-uc-323.webp";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
const Categories = ({ otherPageBanner, banners }) => {
  return (
    <div>
      <OtherHeader
        otherHeaderPagesLink="Çekilişler"
        banners={banners}
        otherPageBanner={otherPageBanner}
      />
      <div className="container all_shops_search_container">

        <form className="other_games_input">
          <input
            type="search"
            placeholder="Buradan kateqori arayabilirsiniz."
          />
          <button>
            <CiSearch className="header_second_search" />
          </button>
        </form>

      </div>
      <main className="categories_page_container_mobile">
        
          {/* <div onClick={() => dispatch(modalOverlayFunc())} className='overlay'></div> */}

          <div className="category_modal_container_parent container">
            {HomePagecategories?.slice(0, 28).map((data, i) => (
              <Link to={""} key={i}>
                <div className="category_modal_container_child">
                  <img src={Avatar} alt="" />
                  <p>{data?.category}</p>
                </div>
              </Link>
            ))}
          
        </div>
      </main>


      <main className="categories_page_container container">
        {HomePagecategories.map((el, i) => {
          return (
            <div
              className="categories_card"
              style={{ background: `url(${Avatar})` }}
            >

              <p>{el.category}</p>
            </div>
          );
        })}
      </main>
      <footer>
        <div className="footer_parent_container">
          {/* <FooterFirstContainer /> */}
          <FooterSecondContainer />
          <FooterThirdContainer />
        </div>
        <FooterEndContainer />
      </footer>
    </div>
  );
};

export default Categories;
