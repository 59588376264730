import React from "react";
import { Link } from "react-router-dom";
import {
  FaAngleRight,
  FaArrowAltCircleRight,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import DiscordImg from "../images/widget.png";
import LogoImg from "../images/logo-light.svg";
import Instagram from "../images/instagram-ar21.svg";
import Whatsapp from "../images/whatsapp.png";
const FooterThirdRightCard = () => {
  return (
    <div className="footer_third_right_card">
      <h6>Bizi Takip Et</h6>
      <div className="media-card">
        {" "}
        <Link
          className="discord_link"
          to="https://discord.com/invite/UtjXNfpdG9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="card-body">
            <img src={LogoImg} />
            {/* <p>1200 online</p> */}
          </div>
          <div className="card-footer">
            <img src={Instagram} />{" "}
            <span>
              chat now <FaAngleRight />
            </span>
          </div>
        </Link>
      </div>
      <div className="media-card">
        {" "}
        <Link
          className="discord_link"
          to="https://discord.com/invite/UtjXNfpdG9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="card-body whatsapp ">
            <img src={LogoImg} />
            {/* <p>1200 online</p> */}
          </div>
          <div className="card-footer whatsapp">
            <img src={Whatsapp} />{" "}
            <span>
              chat now <FaAngleRight />
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default FooterThirdRightCard;
