import React, { useEffect, useState } from "react";
import PlayerMarketFilterCard from "./PlayerMarketFilterCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductListFilter,
  getProducts,
  getProductsFilter,
} from "../actions/MainAction";
import { Link } from "react-router-dom";
import MainSecondCard from "./MainSecondCard";
import { FaFilter } from "react-icons/fa";
import { AiOutlineControl } from "react-icons/ai";

const ShopProfileFilterCardsContainer = ({ shopProducts }) => {
  const [active, setActive] = useState("Tüm ürünler");
  const dispatch = useDispatch();
  const items = ["Tüm ürünler", "PUBG", "Valorant", "CS:GO", "FC25", "CD Key"];
  const handleButtonClick = (buttonName) => {
    setActive(buttonName);
    localStorage.setItem("activeList", buttonName);
  };
  const { productListFilter, filterProducts } = useSelector(
    (state) => state.Data
  );
  console.log(filterProducts);
  useEffect(() => {
    dispatch(getProductsFilter());
  }, [dispatch]);

  // URL formatlama funksiyası
  const formatUrl = (title, id) => {
    const formattedTitle = title
      .replace(/\//g, "-") // / işarəsini əvəz edir
      .replace(/\?/g, "") // ? işarəsini çıxarır
      .trim() // Başda və sondakı boşluqları çıxarır
      .replace(/\s+/g, "") // Aradakı boşluqları çıxarır
      .toLowerCase(); // Kiçik hərflərə çevirir

    return `/${formattedTitle}/${id}`;
  };
  return (
    <div className="player_market_filter_cards_container  ">
      <div className="player_market_filter_cards_filter">
        <h2>
          {" "}
          <FaFilter /> Filter
        </h2>
        <div className="player_market_filter_cards__filter_sidebar">
          <ul>
            {items.map((el, i) => (
              <li
                onClick={() => handleButtonClick(el)}
                key={i}
                className={`${active === el ? "activeList" : ""}`}
              >
                {el}
              </li>
            ))}
          </ul>
          <div className="range_price_container">
            <h2>
              {" "}
              <AiOutlineControl /> Fiyat aralığı
            </h2>
            <div className="range_price">
              <input type="text" placeholder="min" />
              <input type="text" placeholder="max" />
            </div>
          </div>
        </div>
      </div>
      <div className="player_market_filter_cards_container_inner">
        {shopProducts?.map((data, i) => (
          <>
            <Link key={i} to={formatUrl(data.title, data.id)}>
              <MainSecondCard data={data} />
            </Link>
            <Link key={i} to={formatUrl(data.title, data.id)}>
              <MainSecondCard data={data} />
            </Link>
          </>
        ))}
      </div>
    </div>
  );
};

export default ShopProfileFilterCardsContainer;
