import React, { useState } from "react";

import image from "../../images/zxn7pwAqsTCEPSQUVvOaNtoK3.webp";
import image1 from "../../images/GiqRkmbfsx1groB3NaztU5wKC.webp";
import { useSelector } from "react-redux";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FcOk } from "react-icons/fc";
const MembershipPackages = () => {
  const { userObj } = useSelector((state) => state.Data);

  const [number, setNumber] = useState(userObj.phone_number || "");
  const [email, setEmail] = useState(userObj.email || "");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [image, setImage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showError, setShowError] = useState(false);
  const idValidationImgFn = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };
  const createShopFn = (e) => {
    e.preventDefault();
    if (number && email && image) {
      setShowPopup(true);
      setShowError(false);
      console.log({
        number,
        email,
        image,
        showPopup,
      });
    } else {
      setShowError(true);
    }
  };
  return (
    <div className="membershipPackages container">
      <h2> Mağaza Oluştur </h2>
      <p>
        {" "}
        Mağaza oluşturabilmek için aşağıdaki adımların tamamlanması
        gerekmektedir:
        <ul>
          <li>TC kimlik fotoğrafı yüklenmeli.</li>
          <li>Telefon numarası onaylanmalı.</li>
          <li>E-posta adresi onaylanmalı.</li>
        </ul>
        Bu adımlardan biri tamamlanmazsa mağaza oluşturma işlemi
        gerçekleştirilemez. Tüm adımlar tamamlandığında başvurunuz yönetici
        tarafından incelenecektir.
      </p>
      {/* <div className="membershipPackages__box">
        <a>
          <div className="membershipPackages__card">
            <div className="membershipPackages__card--img">
              <img src={image} alt="" />
            </div>
            <h5 className="membershipPackages__card--title">
              Kullanıcı Adı Değişikliği Paketi
            </h5>
            <p className="membershipPackages__card--desc">
              Kullanıcı adı değişikliği paketi ile İtemSatış kullanıcı adınızı
              bir takım şartları karşılıyorsanız ücret karşılığında
              değiştirebilirsiniz. Detaylı bilgi için lütfen paket sayfasınız
              ziyaret edin.
            </p>
          </div>
        </a>

        <a>
          <div className="membershipPackages__card">
            <div className="membershipPackages__card--img">
              <img src={image1} alt="" />
            </div>
            <h5 className="membershipPackages__card--title">
              Otomatik İlan Yukarı Taşıma Paketi
            </h5>
            <p className="membershipPackages__card--desc">
              Üyeliğinize göre belirlediğiniz ilanlarınızı süresi dolduğunda
              otomatik olarak yukarı taşımaya yarayan bu paket için detaylı
              bilgiyi paket sayfasından öğrenebilirsiniz.{" "}
            </p>
          </div>
        </a>
      </div> */}
      <form action="" onSubmit={createShopFn}>
        <div className="id_validation_container">
          <label htmlFor="">
            İsminizi giriniz:
            <br />
            {showError && !name && <p>isim boş bırakılamaz</p>}
            <input
              className={`${showError && !name && "error"}`}
              onChange={(e) => setName(e.target.value)}
              type="text"
              defaultValue={name}
              placeholder="Isminizi giriniz"
            />
          </label>
          <label htmlFor="">
            Soyisminizi giriniz:
            <br />
            {showError && !surname && <p>soyisim boş bırakılamaz</p>}
            <input
              className={`${showError && !surname && "error"}`}
              onChange={(e) => setSurname(e.target.value)}
              type="text"
              defaultValue={surname}
              placeholder="Soyisminizi giriniz"
            />
          </label>
          <label htmlFor="">
            doğum tarihinizi giriniz:
            <br />
            {showError && !birthDay && <p>doğum tarihi boş bırakılamaz</p>}
            <input
              className={`${showError && !birthDay && "error"}`}
              onChange={(e) => setBirthDay(e.target.value)}
              type="date"
              defaultValue={birthDay}
              //   placeholder="+999-000-00-00"
            />
          </label>
          <label htmlFor="">
            Numaranızı giriniz:
            <br />
            {showError && !number && <p>numara boş bırakılamaz</p>}
            <input
              className={`${showError && !number && "error"}`}
              onChange={(e) => setNumber(e.target.value)}
              type="tel"
              defaultValue={number}
              placeholder="+999-000-00-00"
            />
          </label>
          <label htmlFor="" className="id_validation_email">
            Emailinizi giriniz: <br />
            {showError && !email && <p>numara boş bırakılamaz</p>}
            <input
              className={`${showError && !email && "error"}`}
              type="email"
              defaultValue={email}
              placeholder="example@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor="id_validation" className="id_validation_input">
            {image ? (
              <img src={image} alt="" />
            ) : (
              <span>
                <IoCloudUploadOutline />
                <span>Fotoğrafı sürükle ve ya dosya ekle</span>
                {showError && !image && <p>fotoğraf boş bırakılamaz</p>}
              </span>
            )}
            <input
              type="file"
              id="id_validation"
              accept="image/*"
              hidden
              onChange={(e) => idValidationImgFn(e)}
            />
          </label>
          <button type="submit">Sorgu yolla</button>
        </div>
        {showPopup && (
          <div className="id_validation_popup">
            <div className="id_validation_success">
              <div className="id_validation_success_icon">
                <FcOk />
              </div>
              <p>
                Sorgu başarıyla gönderildi. <br />
                yönetici bilgilerinizi kontrol ettikten sonra <br /> maille geri
                bildirim verilecek!
              </p>
              <span onClick={() => setShowPopup(!showPopup)}>kapat</span>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default MembershipPackages;
