import React from "react";

const Achievements = () => {
  const userBadges = [
    {
      img: "https://cdn.itemsatis.com/uploads/medals/itemsatis-tedarikci-medal.png",
      title: "Hızlı Teslimatçı",
      description: "Bu kullanıcı 100'den fazla siparişi anında teslim etti.",
    },
    {
      img: "https://cdn4.iconfinder.com/data/icons/education-759/2050/Education_flat-14-512.png",

      title: "Elite Satıcı",
      description:
        "Bu mağaza 50.000 ile 75.000 arasında başarılı satış gerçekleştirdi.",
    },
    {
      img: "https://static.vecteezy.com/system/resources/previews/007/479/911/non_2x/icon-reliable-suitable-for-feedback-symbol-blue-eyes-style-simple-design-editable-design-template-simple-symbol-illustration-vector.jpg",
      title: "Güvenilir Alıcı",
      description: "Bu üyenin tüm siparişleri sorunsuz olarak tamamlandı.",
    },
    {
      img: "https://img.freepik.com/premium-vector/verified-vector-icon-account-verification-verification-icon_564974-1246.jpg",
      title: "Onaylı E-Ticaret Mağazası",
      description:
        "Bu mağaza sistemimizde doğrulanmış ve güvenilir olarak işaretlenmiştir.",
    },
  ];

  return (
    <div className="shop_section_achievements container">
      {userBadges.map((el, i) => (
        <div className="shop_section_achievements_card">
          <img src={el.img} alt="" />
          <div className="achievements_descript">
            <h2>{el.title}</h2>
            <p>{el.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Achievements;
