import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { RxSlash } from "react-icons/rx";
import { Link } from "react-router-dom";

const OtherHeaderPagesLink = ({ otherHeaderPagesLink }) => {
  return (
    // <div className="other_header_pages_link container">
    //     {/* <Link to='/'>Anasayfa</Link>
    //     <RxSlash /> */}
    //     <div className='other_header_pages_link_child'>
    //         <Link to='/'>Anasayfa</Link>
    //         <FaAngleRight />
    //     </div>
    //     <p>{otherHeaderPagesLink}</p>
    // </div>
    <div></div>
  );
};

export default OtherHeaderPagesLink;
