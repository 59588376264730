import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../actions/MainAction";
import MainSecondCard from "./MainSecondCard";
import { Link } from "react-router-dom";

const MainThirdContainer = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.Data);
  let langText = localStorage.getItem("selectedLanguage");

  useEffect(() => {
    dispatch(getProducts(langText));
  }, [dispatch]);

  // URL formatlama funksiyası

  return (
    <div className="main_second_container container">
      {products?.map((data, i) => (
        <>
          <Link to={"/"} key={i}>
            <MainSecondCard data={data} />
          </Link>
          <Link to={"/"} key={i}>
            <MainSecondCard data={data} />
          </Link>
          <Link to={"/"} key={i}>
            <MainSecondCard data={data} />
          </Link>
          <Link to={"/"} key={i}>
            <MainSecondCard data={data} />
          </Link>
          <Link to={"/"} key={i}>
            <MainSecondCard data={data} />
          </Link>
        </>
      ))}
    </div>
  );
};

export default MainThirdContainer;
