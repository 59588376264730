import React from "react";
import FooterSecondCard from "./FooterSecondCard";
import FooterSecondLogoCard from "./FooterSecondLogoCard";
import FooterDelivery from "./FooterDelivery";

const FooterSecondContainer = () => {
  return (
    <div className="footer_second_container container">
      {/* <FooterSecondLogoCard /> */}
      <div className="footer_second_inner_container">
        <FooterDelivery />
        <FooterSecondCard />
        {/* <FooterSecondCard />
        <FooterSecondCard />
        <FooterSecondCard /> */}
        {/* <FooterSecondCard /> */}
      </div>
    </div>
  );
};

export default FooterSecondContainer;
