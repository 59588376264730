import React from "react";
import { FaCrown, FaHeart, FaMeteor, FaStar } from "react-icons/fa";

const MainSecondButtons = ({ activeButton, onButtonClick }) => {
  return (
    <div className="main_second_buttons main_second_buttons_home container">


      <button
        className={
          activeButton === "Vitrin İlanları" ? "main_second_active_button" : ""
        }
        onClick={() => onButtonClick("Vitrin İlanları")}
      >
        <FaCrown /> Vitrin İlanları
      </button>

      <button
        className={
          activeButton === "Yeni İlanlar" ? "main_second_active_button" : ""
        }
        onClick={() => onButtonClick("Yeni İlanlar")}
      >
        <FaMeteor /> Yeni İlanlar
      </button>
      <button
        className={
          activeButton === "Popüler İlanlar" ? "main_second_active_button" : ""
        }
        onClick={() => onButtonClick("Popüler İlanlar")}
      >
        <FaHeart /> Popüler İlanlar
      </button>
      <button
        className={
          activeButton === "Favori İlanlar" ? "main_second_active_button" : ""
        }
        onClick={() => onButtonClick("Favori İlanlar")}
      >
        <FaStar /> Favori İlanlar
      </button>
    </div>
  );
};

export default MainSecondButtons;
