import React from "react";
import { notifications } from "../mock/data";

const NotificationContainer = () => {
  function formatTime(timestamp) {
    // Tarih ve saati ayırıyoruz
    const [datePart, timePart] = timestamp.split(" ");

    // Saat, dakika ve saniyeyi ayırıyoruz
    const [hours, minutes] = timePart.split(":");

    // Sadece saat ve dakikayı döndürüyoruz
    return `${hours}:${minutes}`;
  }
  return (
    <div className="notification_panel">
      <div className="notification_panel_header">
        <h2>Bildirimler </h2>
        <span></span>
        <p>Okunmuş olarak işaretle</p>
      </div>
      <div className="notifications_cards">
        {notifications.map((el, i) => (
          <div className="notification_card">
            <div className="notification_header">
              {el.isRead && <span className="notification_circle"></span>}
              <span className="notification_type">#{el.type}</span>
              <span className="notification_content">{el.content}</span>
            </div>
            <div className="notification_footer">
              <span>{el.timestamp.split(" ")[0]}</span>
              <span>{formatTime(el.timestamp)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationContainer;
