import React, { useState } from "react";
import "../css/cdKeys.css";
import FooterBox from "./FooterBox"; // FooterBox componentini daxil edirik
import {
  allProducts,
  countries,
  devices,
  filters,
  gameTypes,
  platforms,
} from "../mock/data";
import { Link } from "react-router-dom";
import MainSecondCard from "../components/MainSecondCard";
import OtherHeader from "../components/OtherHeader";
import FooterSecondContainer from "../components/FooterSecondContainer";
import FooterThirdContainer from "../components/FooterThirdContainer";
import FooterEndContainer from "../components/FooterEndContainer";
const formatUrl = (title, id) => {
  const formattedTitle = title
    .replace(/\//g, "-")
    .replace(/\?/g, "")
    .trim()
    .replace(/\s+/g, "")
    .toLowerCase();

  return `/${formattedTitle}/${id}`;
};

const footerData = [
  {
    title: "CD Key Nedir?",
    content: [
      {
        subTitle: "CD Key Nedir?",
        text: "CD Key, oyunseverlerin sıkça karşılaştığı bir terimdir. Oyun satın alındığında, oyunu yüklemek ve oynamak için anahtar kod olarak da adlandırılan bu CD Key'e ihtiyaç duyulur.",
      },
      {
        subTitle: "CD Key Özellikleri:",
        text: "CD Key, bir yazılımın veya oyunun yasal olarak kullanılabilmesi için gereken lisans anahtarını temsil eder. Bu anahtar, ürünü satın alan kişiye özeldir ve kopya kullanımı önlemek amacıyla kullanılır. CD Key, ürünün orijinal olduğunu və lisanslı bir şekilde kullanılabileceğini gösterir.",
      },
    ],
    listItems: {
      title: "CD Key Özellikleri:",
      items: [
        "Özgünlük: Her CD Key, belirli bir yazılım veya oyun için benzersizdir.",
        "Lisanslama: Ürünün yasal sahibi tarafından kullanılmasına olanak tanır.",
        "Güvenlik: Kopya kullanımını engelleyerek ürünün orijinalliğini korur.",
      ],
    },
    extraContent: [
      "CD key, genellikle video oyunları və yazılımların lisanslama sürecinde kullanılan bir kod sistemidir. Oyun və ya yazılım satın alındığında, bu CD key vasitəsilə lisanslama işlemi gerçəkləşir.",
      "CD key satın alırken İtemSatış gibi güvenilir satıcıları tercih etmək önemlidir.",
    ],
  },
  {
    title: "Steam CD Key Satın Al",
    content: [
      {
        subTitle: "Neden Steam CD Key satın almalıyım?",
        text: "Steam CD Key, oyun satın alırken güvenli bir ödeme sistemi sunar ve kullanım kolaylığı ilə bilinir. Bu, oyunun sürekli olaraq kullanılmasını və sahte kopyalarının önlenmesini sağlar.",
      },
    ],
    listItems: {
      title: "Steam CD Key Özellikleri:",
      items: [
        "Güvenlik: Güvenli bir ödeme sistemi sunar.",
        "Kullanım Kolaylığı: Aktivasyon kodunu basit şekilde kullanmak mümkündür.",
        "Oyunun Kullanım Süresi: Oyunun sahte kopyalarının önlenmesini sağlar.",
      ],
    },
    extraContent: [
      "Steam platformu, binlerce oyunseverin favori oyunlarını oynadığı bir yerdir. En popüler oyunları satın alırken dikkatli olmanız gereken ipuçlarıyla tanış olun.",
    ],
  },
  {
    title: "Xbox CD Key Satın Al",
    content: [
      {
        subTitle: "Xbox CD Key Nedir?",
        text: "Xbox oyunları için CD Key satın alarak, oyunları daha uygun fiyatlarla elde edebilirsiniz.",
      },
    ],
    listItems: {
      title: "Xbox CD Key Özellikleri:",
      items: [
        "Hızlı Erişim: Anında oyun oynama imkanı sağlar.",
        "Ekonomik Fiyatlar: Daha uygun fiyata oyun sahibi olma şansı.",
        "Güvenli Alışveriş: Oyunları rahatça satın almanızı sağlar.",
      ],
    },
    extraContent: [
      "Xbox oyun dünyasına adım atarak en iyi CD key satın alma yöntemlerini öğrenin!",
    ],
  },
  {
    title: "EA CD Key Satın Al",
    content: [
      {
        subTitle: "EA CD Key Nedir?",
        text: "EA CD Key, Electronic Arts tərəfindən geliştirilen oyunları dijital olaraq aktive etmenizi sağlayan benzersiz bir üründür.",
      },
    ],
    listItems: {
      title: "EA CD Key Özellikleri:",
      items: [
        "Anında Erişim: EA oyunlarına anında erişin.",
        "Ekonomik Çözüm: Fiziksel kopyalara göre daha uygun fiyat.",
        "Güvenli Alışveriş: Güvenilir platformlardan rahatca satın alın.",
      ],
    },
  },
  {
    title: "Rockstar Games CD Key Satın Al",
    content: [
      {
        subTitle: "Rockstar Games CD Key Nedir?",
        text: "Rockstar Games'in sunduğu oyunları dijital olarak aktive etmeyi sağlar. Bu, Grand Theft Auto, Red Dead Redemption gibi oyunları hızla oynama imkanı verir.",
      },
    ],

    listItems: {
      title: "Rockstar Games CD Key Özellikleri:",
      items: [
        "Hızlı Erişim: Anında oyun oynama imkanı.",
        "Maliyet Etkinliği: Fiziksel kopyalardan daha uygun fiyat.",
        "Geniş Oyun Kataloğu: Rockstar'ın zengin oyun dünyası.",
      ],
    },
  },
];

const CdKeys = ({banners,otherPageBanner}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 25;

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = allProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(allProducts.length / productsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <OtherHeader
        otherHeaderPagesLink="Oyuncu Pazarı"
        banners={banners}
        otherPageBanner={otherPageBanner}
      />
      
      <main>
        <div className="cdkeys-container">
          <div className="header">
            <h1>CD Key Ürünleri</h1>
            <p>En uygun fiyatlı Steam, Xbox, Uplay, Epic Games CD Key ürünleri.</p>
          </div>

          <div className="main-content">
            <div className="filter-container">
              <h3>Fiyat Aralığı (₺)</h3>
              <input type="text" placeholder="En az" />
              <input type="text" placeholder="En çok" />

              <h3>Kelime Filtrele</h3>
              <input type="text" placeholder="Ara..." />

              <h3>Sıralama</h3>
              <select>
                <option value="recommended">Önerilen</option>
                <option value="price-asc">Fiyat Artan</option>
                <option value="price-desc">Fiyat Azalan</option>
              </select>

              <h3>Bölge</h3>
              <div className="region-filter">
                {countries.map((country) => (
                  <div className="checkbox-item" key={country.id}>
                    <input type="checkbox" id={country.id} />
                    <label htmlFor={country.id}>
                      <img
                        src={country.imgSrc}
                        alt={country.imgAlt}
                        className="flag-icon"
                      />
                      {country.label}
                    </label>
                  </div>
                ))}
              </div>

              {/* Platform */}
              <h3>Platform</h3>
              <div className="platform-filter">
                {platforms.map((platform) => (
                  <div className="checkbox-item" key={platform.id}>
                    <input type="checkbox" id={platform.id} />
                    <label htmlFor={platform.id}>
                      <img
                        src={platform.imgSrc}
                        alt={platform.imgAlt}
                        className="platform-icon"
                      />
                      {platform.label}
                    </label>
                  </div>
                ))}
              </div>

              {/* Ön Sipariş Teslimi */}
              <h3>Ön Sipariş Teslimi</h3>
              <div className="filter-checkbox">
                {filters.map((filter) => (
                  <div className="checkbox-item" key={filter.id}>
                    <input type="checkbox" id={filter.id} />
                    <label htmlFor={filter.id}>
                      <img
                        src={filter.imgSrc}
                        alt={filter.imgAlt}
                        className="icon"
                      />
                      {filter.label}
                    </label>
                  </div>
                ))}
              </div>

              {/* Cihaz */}
              <h3>Cihaz</h3>
              <div className="device-filter">
                {devices.map((device) => (
                  <div className="checkbox-item" key={device.id}>
                    <input type="checkbox" id={device.id} />
                    <label htmlFor={device.id}>
                      <img
                        src={device.imgSrc}
                        alt={device.imgAlt}
                        className="device-icon"
                      />
                      {device.label}
                    </label>
                  </div>
                ))}
              </div>

              {/* Oyun Türü */}
              <h3>Oyun Türü</h3>
              <div className="game-type-filter">
                {gameTypes.map((gameType) => (
                  <div className="checkbox-item" key={gameType.id}>
                    <input type="checkbox" id={gameType.id} />
                    <label htmlFor={gameType.id}>
                      <img
                        src={gameType.imgSrc}
                        alt={gameType.imgAlt}
                        className="icon"
                      />
                      {gameType.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {/* Məhsul Siyahısı */}
            <div className="product-list">
              {allProducts?.map((data, i) => (
                <Link key={i} className="item-card">
                  <MainSecondCard data={data} seller={false} />
                 </Link>
              ))}
            </div>
          </div>

          {/* Pagination Bölməsi */}
          <div className="pagination-container">
            <p>
              Toplamda {allProducts.length} adet ürün bulundu, siz sayfada{" "}
              {indexOfFirstProduct + 1} ile {indexOfLastProduct} arasındaki ürünleri
              görüyorsunuz.
            </p>
            <div className="pagination">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                &laquo;
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => paginate(i + 1)}
                  className={currentPage === i + 1 ? "active" : ""}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                &raquo;
              </button>
            </div>
          </div>
          {/* Footer Box-lar */}
          <div className="footer-container">
            {footerData.map((data, index) => (
              <FooterBox
                key={index}
                title={data.title}
                content={data.content}
                listItems={data.listItems}
              />
            ))}
          </div>
        </div>
      </main>

      <footer>
        <div className="footer_parent_container">
          {/* <FooterFirstContainer /> */}
          <FooterSecondContainer />
          <FooterThirdContainer />
        </div>
        <FooterEndContainer />
      </footer>

    </div>

  );
};

export default CdKeys;
