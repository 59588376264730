import React from "react";
import { useParams } from "react-router-dom";
import OtherHeader from "../components/OtherHeader";
import FooterFirstContainer from "../components/FooterFirstContainer";
import FooterSecondContainer from "../components/FooterSecondContainer";
import FooterThirdContainer from "../components/FooterThirdContainer";
import FooterEndContainer from "../components/FooterEndContainer";
import ProfileDetailMain from "../components/ProfileDetailMain";

const Profile = ({ banners, otherPageBanner }) => {
  const { username } = useParams();
  console.log(username);
  return (
    <div className="other_profile_mobile">
      <OtherHeader
        banners={banners}
        otherHeaderPagesLink="Profile"
        otherPageBanner={otherPageBanner}
      />
      <main>
        {/* <section>
                    <ShopProfileSectionFirst id={id} />
                </section> */}
        <ProfileDetailMain />
      </main>
      <footer>
        <div className="footer_parent_container">
          {/* <FooterFirstContainer /> */}
          <FooterSecondContainer />
          <FooterThirdContainer />
        </div>
        <FooterEndContainer />
      </footer>
    </div>
  );
};

export default Profile;
