import React, { useState, useEffect } from 'react';
import Logo from './Logo';
import HeaderSecondInp from './HeaderSecondInp';
import AnnounceCreateModal from './modals/AnnounceCreateModal';
import { useSelector } from 'react-redux';
import HeaderThirdContainer from './HeaderThirdContainer';
import AddAnnounce from './AddAnnounce';
import LightDarkBlog from './LightDarkBlog';
import CdKeys from '../pages/CdKeys';

const HeaderSecondContainer = () => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 810);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 810);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSearchVisibility = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const { announceCreateModal } = useSelector(state => state.Data);

  return (
    <div className='header_second_container'>
      <div className="header_second container">
        <Logo />
        {isLargeScreen && <HeaderSecondInp />}
        <LightDarkBlog />
      </div>

      {!isLargeScreen && isSearchVisible && <HeaderSecondInp />}

      {announceCreateModal && <AnnounceCreateModal />}

      {/* <HeaderThirdContainer 
        isSearchVisible={isSearchVisible}
        toggleSearchVisibility={toggleSearchVisibility}
      /> */}

      {/* <AddAnnounce 
        isSearchVisible={isSearchVisible}
        toggleSearchVisibility={toggleSearchVisibility}
      /> */}


    </div>
  );
};

export default HeaderSecondContainer;
