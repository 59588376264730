import React, { useRef, useState } from "react";
import HeaderSliderFirst from "../images/valorant-gece-pazari-nisan-28294.webp";
import HeaderSliderSecond from "../images/duyuru-nakit-cekim-bayram-14298.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import { Keyboard, Pagination, Navigation, EffectFade } from "swiper/modules";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
const HeaderSliderContainer = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const games = [
    "PUBG Mobile",
    "Mobile Legends",
    "VALORANT",
    "League of Legends",
    "Milli Piyango",
  ];
  const [activeTab, setActiveTab] = useState(null);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        <SwiperSlide>
          <img src="https://cdn1.epicgames.com/offer/cbd5b3d310a54b12bf3fe8c41994174f/EGS_VALORANT_RiotGames_S1_2560x1440-62a5c694f32fd4273a02a86b7f74ef8a" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://www.riotgames.com/darkroom/1440/8d5c497da1c2eeec8cffa99b01abc64b:5329ca773963a5b739e98e715957ab39/ps-f2p-val-console-launch-16x9.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://assetsio.gnwcdn.com/valorant-jett-and-phoenix-wallpaper-b.jpg?width=1600&height=900&fit=crop&quality=100&format=png&enable=upscale&auto=webp" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://variety.com/wp-content/uploads/2019/02/pubg-mobile-1.png?w=1000&h=563&crop=1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://img.youtube.com/vi/mfzgQgMOnao/maxresdefault.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://library.sportingnews.com/styles/twitter_card_120x120/s3/2024-07/EA_FC25_Standard_KeyArt_16-9_3840x2160_Hypermotion.png?itok=_ZwywqKG" />
        </SwiperSlide>
      </Swiper>
      <div className="slider">
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src="https://cdn1.epicgames.com/offer/cbd5b3d310a54b12bf3fe8c41994174f/EGS_VALORANT_RiotGames_S1_2560x1440-62a5c694f32fd4273a02a86b7f74ef8a" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://www.riotgames.com/darkroom/1440/8d5c497da1c2eeec8cffa99b01abc64b:5329ca773963a5b739e98e715957ab39/ps-f2p-val-console-launch-16x9.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://assetsio.gnwcdn.com/valorant-jett-and-phoenix-wallpaper-b.jpg?width=1600&height=900&fit=crop&quality=100&format=png&enable=upscale&auto=webp" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://variety.com/wp-content/uploads/2019/02/pubg-mobile-1.png?w=1000&h=563&crop=1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://img.youtube.com/vi/mfzgQgMOnao/maxresdefault.jpg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="https://library.sportingnews.com/styles/twitter_card_120x120/s3/2024-07/EA_FC25_Standard_KeyArt_16-9_3840x2160_Hypermotion.png?itok=_ZwywqKG" />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="games">
        {games.map((el, i) => (
          <div
            onClick={() => handleTabClick(i)}
            className={activeTab === i ? "game selected" : "game"}
            key={i}
          >
            {el}
          </div>
        ))}
      </div>
    </>
  );
};

export default HeaderSliderContainer;
