import React from "react";
import { MdRocketLaunch } from "react-icons/md";
import { MdSecurity } from "react-icons/md";
import { BsCoin } from "react-icons/bs";
import { FiSmile } from "react-icons/fi";
const FooterDelivery = () => {
  const serviceInfo = {
    fastDelivery: {
      title: "Hızlı Teslimat",
      description: "Aldığınız kod anında hesabınızda! 7/24 teslimat.",
      icon: <MdRocketLaunch />,
    },
    secureShopping: {
      title: "Güvenli Alışveriş",
      description:
        "3D Secure ve SSL güvencesiyle dilediğiniz ödeme yöntemi ile ödeme yapın.",
      icon: <MdSecurity />,
    },
    bestPrices: {
      title: "En Uygun Fiyatlar",
      description:
        "İndirimli ve en ucuz fiyatlarla alışverişin keyfini çıkarın.",
      icon: <BsCoin />,
    },
    customerSatisfaction: {
      title: "Müşteri Memnuniyeti",
      description:
        "Oyun alışverişlerinizde bizi tercih ettiğiniz için teşekkür ederiz.",
      icon: <FiSmile />,
    },
  };

  return (
    <div className="footer-delivery">
      <div className="footer-delivery-card">
        <div className="icon">{serviceInfo.fastDelivery.icon}</div>
        <div className="card-body">
          <h1>{serviceInfo.fastDelivery.title}</h1>
          <p>{serviceInfo.fastDelivery.description}</p>
        </div>
      </div>
      <div className="footer-delivery-card">
        <div className="icon">{serviceInfo.secureShopping.icon}</div>
        <div className="card-body">
          <h1>{serviceInfo.secureShopping.title}</h1>
          <p>{serviceInfo.secureShopping.description}</p>
        </div>
      </div>
      <div className="footer-delivery-card">
        <div className="icon">{serviceInfo.bestPrices.icon}</div>
        <div className="card-body">
          <h1>{serviceInfo.bestPrices.title}</h1>
          <p>{serviceInfo.bestPrices.description}</p>
        </div>
      </div>
      <div className="footer-delivery-card">
        <div className="icon">{serviceInfo.customerSatisfaction.icon}</div>
        <div className="card-body">
          <h1>{serviceInfo.customerSatisfaction.title}</h1>
          <p>{serviceInfo.customerSatisfaction.description}</p>
        </div>
      </div>
    </div>
  );
};

export default FooterDelivery;
