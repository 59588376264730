import React from "react";
import { Link } from "react-router-dom";
import { HiMiniRocketLaunch } from "react-icons/hi2";
const FooterSecondCard = () => {
  const footerInfo = {
    platform: {
      title: "Oyun Dünyası",
      links: [
        { name: "Hakkımızda", url: "/about" },
        { name: "Kurumsal", url: "/corporate" },
        { name: "Kurumsal Değerler", url: "/corporate-values" },
        { name: "Favori Oyunlarım", url: "/favorites" },
        { name: "Oyun Haberleri", url: "/news" },
        { name: "İletişim", url: "/contact" },
      ],
    },
    userSection: {
      title: "Kullanıcı İşlemleri",
      links: [
        { name: "Kayıt Ol", url: "/register" },
        { name: "Şifremi Unuttum", url: "/forgot-password" },
        { name: "Mağazamız", url: "/store" },
        { name: "Oyun Yayıncısı Başvurusu", url: "/publisher-application" },
        { name: "Ödeme Seçenekleri", url: "/payment-methods" },
      ],
    },
    legal: {
      title: "Yasal Bilgiler",
      links: [
        { name: "Kullanıcı Sözleşmesi", url: "/user-agreement" },
        { name: "KVKK", url: "/kvkk" },
        { name: "Çerez Politikası", url: "/cookie-policy" },
        { name: "Gizlilik Sözleşmesi", url: "/privacy-policy" },
        { name: "Rıza Beyanı", url: "/consent-form" },
      ],
    },
    gameMarket: {
      title: "Oyun Pazarı",
      games: [
        { name: "Apex Legends", url: "/games/apex-legends" },
        { name: "Free Fire", url: "/games/free-fire" },
        { name: "Ragnarok Online", url: "/games/ragnarok-online" },
        { name: "Crossfire", url: "/games/crossfire" },
        { name: "Dota 2", url: "/games/dota-2" },
        { name: "Rust", url: "/games/rust" },
      ],
    },
    popularPages: {
      title: "Popüler Sayfalar",
      pages: [
        { name: "Dota 2", url: "/popular/dota-2" },
        { name: "Free Fire Max", url: "/popular/free-fire-max" },
        { name: "Valorant", url: "/popular/valorant" },
        {
          name: "League of Legends: Wild Rift",
          url: "/popular/league-of-legends-wild-rift",
        },
        { name: "Cabal Online", url: "/popular/cabal-online" },
        {
          name: "Mobile Legends: Adventure",
          url: "/popular/mobile-legends-adventure",
        },
      ],
    },
    rankSystem: {
      title: "İlan Rütbe Sistemi",
      description:
        "Oyuncu ilanlarının rütbe sistemine göre sıralandığı sayfamıza göz atın.",
    },
  };

  return (
    <div className="footer-cards">
      <div className="footer-card">
        <h1>{footerInfo.userSection.title}</h1>
        <div className="links">
          {footerInfo.userSection.links.map((el, i) => (
            <Link to={`/${el.url}`} key={i}>
              {el.name}
            </Link>
          ))}
        </div>
      </div>
      <div className="footer-card">
        <h1>{footerInfo.legal.title}</h1>
        <div className="links">
          {footerInfo.legal.links.map((el, i) => (
            <Link to={`/${el.url}`} key={i}>
              {el.name}
            </Link>
          ))}
        </div>
      </div>
      <div className="footer-card">
        <h1>{footerInfo.gameMarket.title}</h1>
        <div className="links">
          {footerInfo.gameMarket.games.map((el, i) => (
            <Link to={`/${el.url}`} key={i}>
              {el.name}
            </Link>
          ))}
        </div>
      </div>
      <div className="footer-card">
        <h1>{footerInfo.popularPages.title}</h1>
        <div className="links">
          {footerInfo.popularPages.pages.map((el, i) => (
            <Link to={`/${el.url}`} key={i}>
              {el.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterSecondCard;
