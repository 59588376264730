import React, { useState } from "react";
import { helpCenterData } from "../mock/data";
import "../css/helpCenter.css";
const HelpCenter = () => {
  const [activePanel, setActivePanel] = useState(null);

  const togglePanel = (key) => {
    setActivePanel(activePanel === key ? null : key); // Toggle the active panel
  };
  return (
    <div className="help-center">
      {Object.keys(helpCenterData).map((key) => {
        const category = helpCenterData[key];
        return (
          <div className="panel" key={key}>
            <div
              className="panel-header"
              onClick={() => togglePanel(key)}
              style={{ cursor: "pointer" }}
            >
              <h3>{category.title}</h3>
            </div>
            {activePanel === key && (
              <div className="panel-body">
                {category.questions.map((item, index) => (
                  <div className="question-card" key={index}>
                    <p className="question">
                      <strong>{item.question}</strong>
                    </p>
                    <p className="answer">{item.answer}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default HelpCenter;
