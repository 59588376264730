import React from "react";
import "../css/FooterBox.css"; 

const FooterBox = ({ title, content, listItems, extraContent }) => {
  return (
    <div className="footer-box">
      <h3>{title}</h3>

      {/* Content varsa, başlıq və mətn cüt-cüt göstərilir */}
      {content && content.length > 0 && (
        content.map((item, index) => (
          <div key={index}>
            {item.subTitle && <h4>{item.subTitle}</h4>} 
            {item.text && <p>{item.text}</p>} 
          </div>
        ))
      )}

      {/* ListItems varsa, siyahı başlığı və siyahı elementləri göstərilir */}
      {listItems && listItems.items.length > 0 && (
        <div>
          {listItems.title && <h4>{listItems.title}</h4>} 
          <ul>
            {listItems.items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}

      {/* ExtraContent varsa, əlavə paragraphlar kimi göstərilir */}
      {extraContent && extraContent.length > 0 && (
        extraContent.map((item, index) => (
          <p key={index}>{item}</p>
        ))
      )}
    </div>
  );
};

export default FooterBox;
