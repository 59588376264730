import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "./pages/Home";
import Games from "./pages/Games";
import otherPageBanner from "./images/26hZAJzIm8pBKR5PMibtNGCUy.webp";
import OyuncuPazari from "./images/oyuncu-pazari.webp";
import AlimIlan from "./images/alim ilan.webp";
import Cekilis from "./images/cekilis.webp";
import Magaza from "./images/magaza-listesi-itemsatis.webp";
import PlayerMarket from "./pages/PlayerMarket";
import DetailProduct from "./pages/DetailProduct";
import About from "./pages/About";
import Login from "./components/modals/Login";
import Announcement from "./pages/Announcement";
import Shops from "./pages/Shops";
import AllShops from "./pages/AllShops";
import Favories from "./pages/Favories";
import LoaderContainer from "./components/LoaderContainer";
import DetailAuction from "./pages/DetailAuction";
import DetailNews from "./pages/DetailNews";
import News from "./pages/News";
import Auctions from "./pages/Auctions";
import ShopProfile from "./pages/ShopProfile";
import Profile from "./pages/Profile";
import ProfileDetails from "./pages/ProfileDetails";
import Stores from "./pages/Stores";
import Giveaway from "./pages/Giveaway";
import AddAdvert from "./pages/AddAdvert";
import CdKeys from "./pages/CdKeys";
import FooterNavbar from "./components/FooterNavbar";
import Notification from "./pages/Notification";
import Support from "./pages/Support";
import Categories from "./pages/Categories";
import Messages from "./pages/Messages";

const App = () => {
  const { loginRegisterModal, loader } = useSelector((state) => state.Data);

  let banners = [
    {
      id: 1,
      back: OyuncuPazari,
      head: "İtemSatış İlan Pazarı",
      content: "Ücretsiz ilan oluşturabileceğiniz %100 güvenli oyuncu pazarı!",
    },
    {
      id: 2,
      back: AlimIlan,
      head: "Alım İlanları Pazarı",
      content:
        "Alışverişinizi Hızlandırın,Alım İlanlarıyla kolayca aradığınızı bulun!",
    },
    {
      id: 3,
      back: Cekilis,
      head: "Ücretsiz Çekilişler",
      content:
        "Mağazalar tarafından oluşturulan birbirinden farklı ücretsiz çekilişler!",
    },
    {
      id: 4,
      back: Magaza,
      head: "Öne Çıkan Mağazalar",
      content: "Ücretsiz mağaza başvurusu yapın,Satışlarınızı arttırın!",
    },
  ];

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path='/games' element={<Games otherPageBanner={otherPageBanner} />} /> */}
        <Route
          path="/about"
          element={
            <About banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/game-shop"
          element={
            <PlayerMarket banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/:productName/:id"
          element={<DetailProduct otherPageBanner={otherPageBanner} />}
        />
        <Route
          path="/my-announcements"
          element={<Announcement otherPageBanner={otherPageBanner} />}
        />
        <Route
          path="/my-favories"
          element={
            <Favories banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/shops"
          element={
            <Shops banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/all-shops"
          element={
            <AllShops banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/:auctionName/:product-id/:id"
          element={<DetailAuction otherPageBanner={otherPageBanner} />}
        />
        <Route
          path="/:newsName/detail/:id"
          element={<DetailNews otherPageBanner={otherPageBanner} />}
        />
        <Route
          path="/blog"
          element={<News banners={banners} otherPageBanner={otherPageBanner} />}
        />
        <Route
          path="/auctions"
          element={<Auctions otherPageBanner={otherPageBanner} />}
        />
        <Route
          path="/shop-profile/:id"
          element={
            <ShopProfile banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/profile/:username"
          element={
            <Profile banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route path="/control-center" element={<ProfileDetails />} />
        <Route
          path="/stores"
          element={
            <Stores banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/giveaway"
          element={
            <Giveaway banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/add-advert"
          element={
            <AddAdvert banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/cd-keys"
          element={
            <CdKeys banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/notification"
          element={
            <Notification banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/support"
          element={
            <Support banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/categories"
          element={
            <Categories banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
        <Route
          path="/messages"
          element={
            <Messages banners={banners} otherPageBanner={otherPageBanner} />
          }
        />
      </Routes>

      {loginRegisterModal && <Login />}
      {loader && <LoaderContainer />}
      <FooterNavbar />
    </div>
  );
};

export default App;
